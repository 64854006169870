import { SaveOrUpdateTuneError, SaveTuneError, SendTuneError, ToastEnum, UpdateTuneError } from './../../classes-enums-interfaces-types/enums/enums.enum';
import { HelperFunctionsService } from '../../services/helper-functions/helper-functions.service';
import { Location } from '@angular/common';
import { PlayerService } from '../../services/music/player.service';
import { mGlobal } from '../../mglobal';
import {  Tune } from '../../classes-enums-interfaces-types/classes/classes';

import { TuneTrackV1 } from '../../classes-enums-interfaces-types/classes/classes'
import { MyServerService } from '../../services/my-server/my-server.service';
import { ITune } from '../../classes-enums-interfaces-types/interfaces/list-elems/list-elems.model';
import { Component, Input, Output, OnInit, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AlertController, IonicModule, NavController, ToastController } from "@ionic/angular"
import { NgNavigatorShareService } from 'ng-navigator-share';
import { ENV } from '../../../../environments/environment'
import { FindTuneStateService } from '../../services/states/find-tune/find-tune-state.service';

import { CanShareResult, Share, ShareResult } from "@capacitor/share"
import { SaveSendAction } from './footer-bar.module';
import { NavigationEnd, Router } from '@angular/router'; 
import {Err, err, errAsync, okAsync, Result, ResultAsync} from 'neverthrow'
import { SaveOrUpdateTune, SaveOrUpdateTuneRes, SaveTuneRes } from '../../classes-enums-interfaces-types/types/types';
import * as _ from 'lodash';
import { GlobalService } from '../../services/global.service';
import { type } from 'os';
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';
@Component({
    selector: 'app-footer-bar',
    templateUrl: './footer-bar.component.html',
    styleUrls: ['./footer-bar.component.scss'],
})
export class FooterBarComponent implements OnInit, AfterViewInit {

    @Input() selectedTune: Tune
    @Input() saveSendAction: SaveSendAction = SaveSendAction.send // if null will error if forget
    @Input() getTuneUpdate: Function = null
	@Input() setTuneSaveFields: Function = null
	
    @Input() tuneSaveSucc: Function = null
    @Input() goBackAfterSaveSendAction : boolean = false
    @Input() sendDisabled : boolean = false

    // EventEmitter<Tune> = new EventEmitter()
    @Output() barBackEmittor = new EventEmitter();
	@Output() tuneSavedOrSent = new EventEmitter();

    barBackEmittorObserved: any;
	lastRoute

	homeSel
	librarySel

	canGoBack : boolean = false

	inAppBrowser : boolean = false

	isIos = true;

    constructor(
        public mShare: NgNavigatorShareService,
        public findTuneState: FindTuneStateService,
        private mServer: MyServerService,
        public toastController : ToastController,
        private router : Router,
        public alertController: AlertController,
        public ps : PlayerService,
        private location: Location,
        private hF : HelperFunctionsService,
		private gs : GlobalService,
		private eh : ErrorHandlerService,
		private changeDetector : ChangeDetectorRef
        
    ) {

     }

    ngOnInit() {

	}

	 ngAfterViewInit(){

        console.log('footerX init')
		if(history.length > 0){
			this.canGoBack = true
		}

		this.inAppBrowser = this.gs.isRunningInMessengerBrowser()
		this.isIos = this.gs.isRunningIos()

		this.barBackEmittorObserved = this.barBackEmittor.observers.length > 0;
		console.log('footerX afterinit')
		
		if(this.router.url){
			if (this.router.url.includes('find-tune')){
				this.librarySel = false
				this.homeSel = true

			} else if(this.router.url.includes('my-library')){
				this.homeSel = false
				this.librarySel = true
			}
		}

		this.ps.selectedTune$.subscribe((selectedTune : Tune)=> {
			this.selectedTune = selectedTune
			this.changeDetector.detectChanges()
		})

     }
	 ngOnDestroy(){
		console.log('footerX destory')
	 }

    saveSendActionEnum = SaveSendAction

    customSendIcon = '../../assets/send-outline.svg'
    customSaveIcon = '../../assets/save-outline.svg'
    customLibraryIcon = '../../assets/library-outline.svg'
    customChatIcon = '../../assets/chatbubble-outline.svg'
    customBackIcon = '../../assets/arrow-back-outline.svg'
    logoIcon = '../../assets/logo_philip_black_n_white_v1.svg'
    farRightButtonIcon = this.customSendIcon
	 
	// disable backclick w/o graying it out while waiting for tune to saveUpdate, otherwise save action can be canceled
	// if user presses back while saving
	waitingForTuneSaveOrUpdate = false
	
	//cant easily give input to function as its backed in the footer component, set input variable instead

    sendTune() : ResultAsync<true, (SendTuneError | any)[]> {
        let res : ResultAsync<true, (SendTuneError | any)[]> 

        if (this?.selectedTune?.tuneNumber) {
            const tuneNumber = this.selectedTune.tuneNumber

            res = ResultAsync.fromPromise<CanShareResult,any>(Share.canShare(), e => e)
            .mapErr<[any,SendTuneError]>(e => {
                return [e, SendTuneError.shareNotSupported]
            })
            .andThen<true, (SendTuneError | any)[]>( (value1 : CanShareResult)  => {
                return ResultAsync.fromPromise<ShareResult,string>(
                    Share.share({
                        url: `${ENV.DOMAIN_OF_FRONTEND}/play-tune?tune=${tuneNumber}`,
						// needs to be commented out for webshare to pick up on og:image
                        // title: 'Tune it',
                        // text: `Tune it - Music GIFs\nPlay Tune here\n\n`,
                        // dialogTitle: 'Select where to share the Tune',
                    }),
                	(ee : unknown) => {
						const e : DOMException = ee as DOMException
						console.error(`Error sending Tune`,e)
						console.log(0)
						console.log(JSON.stringify(e,Object.getOwnPropertyNames(e)))
						console.log(1)
						console.log(e)
						console.log(2)
						console.log(JSON.stringify(e))
						this.eh.logSentryError(e)
						return e.message 
					}
                )
                .andThen( (value2 : ShareResult) => {
                    return okAsync(true as true)
                })
                .mapErr( (e : string ) => {
                    return [e, SendTuneError.errorSharing]
                })
            })
        } else {
            res =  errAsync([SendTuneError.noTuneNumber])
        }
        return res
        
    }

    saveOrUpdateTune() : SaveOrUpdateTuneRes 
    {
		this.waitingForTuneSaveOrUpdate = true // could be moved tighter time window but prop. no need
        let tune : SaveOrUpdateTuneRes 
		let tuneSync : Tune
        let err : SaveOrUpdateTuneError
		//Only needed for save and not update, before validation #opt, 
		if(!this.selectedTune._id){ 
			// returns a clone, in order not to assign addedTagsTo Tags before server has approved
			tuneSync = this.setTuneSaveFields() 
		} else {
			// #opt to assign lyrics to tune before validate
			tuneSync = this.setTuneSaveFields() 
		}

		const validateRes = mGlobal.validateTune(tuneSync)
		if(validateRes.isErr()){
			tune = errAsync([...validateRes.error, SaveOrUpdateTuneError.validateTuneError])
		} else {

			if(!tuneSync._id){
				tune = this.mServer.saveTune(tuneSync)
				err = SaveOrUpdateTuneError.saveTuneError
			} else {
				tune = this.mServer.updateTune(tuneSync._id, this.getTuneUpdate())   
				err = SaveOrUpdateTuneError.updateTuneError
			}

			this.waitingForTuneSaveOrUpdate = false
			tune = tune.mapErr(e=> {
				return[...e , err]
			})
		}

		this.waitingForTuneSaveOrUpdate = false
		return tune
    }

    updateTune() : ResultAsync<Tune,(UpdateTuneError[])>{
 
        let tune = this.mServer.updateTune(this.selectedTune._id, this.getTuneUpdate())   
        tune = tune.mapErr(e => {
            // #Opt Needs to know that server is lowest level and doesn't return array
            // ... operator on single string returns characters 
            return [UpdateTuneError.updateTuneError]
        })
        return tune
    }

    saveTune() : SaveTuneRes {

        //1 See if saveServer errors
		
		// this is result async
        let tune = this.mServer.saveTune(this.selectedTune)
        //resAsyncA.push(saveTuneServerResAc)
		this.sendDisabled = true
		setTimeout(() => {
			this.sendDisabled = false // should never happend
		}, 4000)
        //2 Create array of ResultAsyncs
        tune = tune.mapErr(e => {
            // #Opt Needs to know that server is lowest level and doesn't return array
            // ... operator on single string returns characters 
			this.sendDisabled = false
			this.changeDetector.detectChanges()
            return [SaveTuneError.saveTuneServerError]
        })
        
		
        return tune

    }

    homeBtnClick(){
        //this.ps.pauseTrack()

        if(ENV.DEBUG){
			//this.router.navigate(['/find-tune'])
            this.router.navigate(['/dev-router'])
        } else {
            this.router.navigate(['/find-tune'])
        }
		
    }

	myLibraryClick(){
		//this.ps.pauseTrack()
		this.router.navigate(['/my-library'])
	}

    chatClicked(){
        this.router.navigate(['/chat'])
    }

	//#opt make only one showToastCall for error or succ
    async saveSendClicked(){
        let toastMsg
		try{
			if(this.selectedTune){
				let tuneRes //: SaveOrUpdateTuneRes // #opt nice to get working, change async type to sync after await
				switch(this.saveSendAction){
					case SaveSendAction.save:
						tuneRes = await this.saveOrUpdateTune()
						if(tuneRes.isErr()){
							this.gs.showToast({header:tuneRes.error.pop(),msg:tuneRes.error[0],type:ToastEnum.error, duration: 4000})
						} else {
							if(this.selectedTune._id){
								toastMsg = 'The Tune was updated'
							} else{
								toastMsg = 'The Tune was saved'
							}
						}

						break
						case SaveSendAction.send:
							tuneRes = await this.sendTune()
							if(tuneRes.isErr()){

								const lowestErr : string = _.take(tuneRes.error as string[],1)[0] 

								if(_.includes(['Share canceled','Abort due to cancellation of share.'], lowestErr) ){ // android , ios different
									this.gs.showToast({header:tuneRes.error.pop(),msg:tuneRes.error[0],type:ToastEnum.warning})
								} else{
									this.gs.showToast({header:tuneRes.error.pop(),msg:tuneRes.error[0],type:ToastEnum.error})
								}

							} else {
								// toastMsg = 'The Tune was sent'
								// No differentiation between sending and returning w/o sending
								// Return void, misleading to show toast
							}
						
						break
					case SaveSendAction.saveAndSend:
						tuneRes = await this.saveOrUpdateTune()
						if(tuneRes.isErr()){
							this.gs.showToast({header:tuneRes.error.pop(),msg:tuneRes.error[0],type:ToastEnum.error})
						} else{ 
							//overwrites for existing, QnE
							// not really clean, but will do location.back anyway after succ send
							this.selectedTune.tuneNumber = tuneRes.value.tuneNumber
							tuneRes = await this.sendTune()  
							if(tuneRes.isErr()){
								const lowestErr : string = _.take(tuneRes.error as string[],1)[0] 

								if(_.includes(['Share canceled','Abort due to cancellation of share.'], lowestErr) ){ // android , ios different
									this.gs.showToast({header:tuneRes.error.pop(),msg:tuneRes.error[0],type:ToastEnum.warning})
								} else{
									this.gs.showToast({header:tuneRes.error.pop(),msg:tuneRes.error[0],type:ToastEnum.error})
								}
								
							} else {
								toastMsg = 'The Tune was saved'
							}
						}

						break
					default:
						break
				}

		
				if(!tuneRes.isErr()){
					if(toastMsg)
						this.gs.showToast({msg:toastMsg})

					if (this.tuneSavedOrSent.observers.length > 0){
						this.tuneSavedOrSent.emit()
					}
					else if(this.goBackAfterSaveSendAction && !mGlobal.debug && !mGlobal.isAdmin){
						this.location.back()
					}
						//history.state.tune = tuneRes.value
						//this.ps.pauseTrack()

				}
			//this.barBackEmittor.emit()
				} else {
					//this.gs.showToast({})
				}
			}catch(e){
				//#Opt
				if(toastMsg)
					this.gs.showToast({msg:toastMsg})
				
				console.error(`Error saveSendClick`,e)
				this.eh.logSentryError(e)
				//Otherwise it will pass validation next 
				
			//#$2 why do I have to reset lyrics on error?
				//#opt bit messy as selectred tune
				// if(this.selectedTune.lyrics?.wordRange?.startIndex || this.selectedTune?.lyrics?.lyricsStr ){
				// 	_.set(this.selectedTune,"lyrics",null)
				// }
					
				this.eh.logSentryError(e)
				if(this.saveSendAction == SaveSendAction.saveAndSend){
					// only from ET
					this.router.navigateByUrl('/my-library')
				}
			}
    }

    backClick() {
        if(this.barBackEmittorObserved){
            this.barBackEmittor.emit()
        } else if (!this.waitingForTuneSaveOrUpdate) {
			this.gs.setPendingHistoryBackClick(true)
            this.location.back()
        }
    }

	getTabButtonStyles() {
        return { bottom : '1dvh' };
		return {
		  bottom: this.isIos ? '3dvh' : '1dvh'
		};
	}

}