import { PlayerService } from './../../services/music/player.service';
import { Input, Component, OnInit, ChangeDetectorRef, Output, AfterViewInit } from '@angular/core';
import { Animation, AnimationController } from '@ionic/angular';
import { ENV } from '../../../../environments/environment';
import { GlobalService } from '../../services/global.service';
import { UserStoredSettings } from '../../classes-enums-interfaces-types/interfaces/interfaces';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-lyrics',
  templateUrl: './lyrics.component.html',
  styleUrls: ['./lyrics.component.scss'],
})
export class LyricsComponent implements OnInit, AfterViewInit {

	@Input() shouldShowOverride : boolean = false
	@Input() disableScroll : boolean = false
    @Input() set selectedTuneLyrics(value:string){
		// set allows code to be run when value is updated
		//value=`Tell me what you know about night terrors? Nothing
		//`
		if(value){
			//this.shouldShow = true
			this.selectedTuneLyricsFormat = 
			value.charAt(0).toUpperCase() + value.slice(1)

			if(value.slice(-1) == '\n')
				this.selectedTuneLyricsFormat = value.slice(0,-1)
			console.log('slice')
		} else {
			this.shouldShow = false
			this.selectedTuneLyricsFormat = null
			this.changeDetector.detectChanges()
		}
	}



	showLyricsAtNbrOfLoops : number = 2;
	longDebugLyrics = `You thought I wouldn't sell without you, sold nine million

	I'm a survivor (What?)
	I'm not gon' give up (What?)
	I'm not gon' stop (What?)
	I'm gon' work harder (What?)
	I'm a survivor (What?)
	I'm gonna make it (What?)
	I will survive (What?)
	Keep on survivin' (What?)
	I'm a survivor (What?)
	I'm not gon' give up (What?)
	I'm not gon' stop (What?)
	I'm gon' work harder (What?)
	I'm a survivor (What?)
	I'm gonna make it (What?)
	I will survive (What?)
	Keep on survivin' (What?)
	
	I'm a survivor (What?)
	I'm not gon' give up (What?)
	I'm not gon' stop (What?)
	I'm gon' work harder (What?)
	I'm a survivor (What?)
	I'm gonna make it (What?)
	I will survive (What?)
	Keep on survivin' (What?)
	I'm a survivor (What?)
	I'm not gon' give up (What?)
	I'm not gon' stop (What?)
	I'm gon' work harder (What?)
	I'm a survivor (What?)
	I'm gonna make it (What?)
	I will survive (What?)
	Keep on survivin' (What?)`
	

	// Something strange with animation first loop if ngIf hasn't been true before, skips animation
	// Works with loopItr == null in own if statement 
    shouldShow : boolean = false
	shoudlShowFromSettings : boolean = true;
	selectedTuneLyricsFormat : string

    constructor(
        private ps : PlayerService,
        private changeDetector : ChangeDetectorRef,
		private animationCtrl : AnimationController,
		private gs : GlobalService,
	
    ){

	}

    ngOnInit(){


    }

	ngAfterViewInit(){
        
        this.ps.loopItr$.subscribe( (loopItr : number) => {
            console.log(`loopItr: ${loopItr}`)
                // if( (loopItr >= 0 || this.shouldShowExt ) && this.selectedTuneLyrics ){
                //null >= 0 -> true
                if(this.selectedTuneLyricsFormat && this.shoudlShowFromSettings){
                    if(loopItr === this.showLyricsAtNbrOfLoops && loopItr
                        //&& selectedTuneLyricsFormat
                        ){
                        console.log(this.selectedTuneLyrics)
                        console.log(loopItr)
                        this.shouldShow = true
                        
                        this.changeDetector.detectChanges()
                        const fadeOutAnim = this.animationCtrl.create()
                        .addElement(document.querySelector('#lyrics-container'))
                        .duration(150)
                        .iterations(1)
                        .fromTo('opacity', '0.5', '1');
                
                        console.log('init anim')
    
                        fadeOutAnim.play()
                    } else if((loopItr == 0) && this.shouldShow && true && !this.shouldShowOverride ){
                        const fadeOutAnim = this.animationCtrl.create()
                        .addElement(document.querySelector('#lyrics-container'))
                        .duration(150)
                        .iterations(1)
                        .fromTo('opacity', '1', '0')
                        .onFinish(() => {
                            console.log('animFinished')
                            this.shouldShow= false
                            this.changeDetector.detectChanges()
                        })
    
                        fadeOutAnim.play()
                    } 
                }
    
                if(loopItr == null){
                    this.shouldShow= false
                    this.changeDetector.detectChanges()
                }
            })
    
            this.gs.userSettings$.subscribe((settings: UserStoredSettings) => {
                if(settings){
                    if (settings.showLyricsOnFirstLoop) {
                        this.showLyricsAtNbrOfLoops = 1
                    } else {
                        this.showLyricsAtNbrOfLoops = 2
                    }
    
                    if(!settings.hideLyricsAlways){
                        this.shoudlShowFromSettings = true
                    } else {
                        this.shoudlShowFromSettings = false
                        this.shouldShow = false
                    }
                }
    
            })
	}

	// selectedTuneLyricsChanged(){
	// 	if(this.selectedTuneLyrics){
	// 		this.selectedTuneLyricsFormat = 
	// 		this.selectedTuneLyrics.charAt(0).toUpperCase() + this.selectedTuneLyrics.slice(1)
	// 	}
	// }


}
