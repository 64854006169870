import { P } from "@angular/cdk/keycodes"

export const ENV = {

	//DOMAIN_OF_FRONTEND: 'https://tune-it-ae-2.ew.r.appspot.com',
    //DOMAIN_OF_BACKEND: 'https://tune-it-ae-2.ew.r.appspot.com',
    DOMAIN_OF_BACKEND: 'https://tune-it.chat',
    DOMAIN_OF_FRONTEND: 'https://tune-it.chat',
	
    DEBUG_LR: false,
    DOMAIN_OF_LR: 'abc',
	SENTRY_DSN: 'https://17f816b0a2b8a68921aecf026427d7ac@o4505533866049536.ingest.sentry.io/4505998611513344',
    REDIRECT_DEFULT_ANGULAR: 'dev-router',
    DEBUG: false,
	IS_DEBUG_IOS: false,
    PRODUCTION: true,
	PRE_PROD: true,
    FIRE_BASE_APP_CONFIG: {
        apiKey: "AIzaSyACgdEuZZNkvaxOlwLvs-q_vAZ0-w37XrE",
        authDomain: "tune-it-ae.firebaseapp.com",
        projectId: "tune-it-ae",
        storageBucket: "tune-it-ae.appspot.com",
        messagingSenderId: "312446087219",
        appId: "1:312446087219:web:3e59376d6995e8ac15f346"
    },
	DEPLOY_VERSION_MAJOR:0,
	DEPLOY_VERSION_MINOR:363,
	TS_DEBUG_BUILD: true
 
};
