import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';
import { PlayerService } from '../../services/music/player.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loop-progress-bar',
  templateUrl: './loop-progress-bar.component.html',
  styleUrls: ['./loop-progress-bar.component.scss'],
})
export class LoopProgressBarComponent implements OnInit, AfterViewInit {

  constructor(
    private ps : PlayerService,
    private changeDetector: ChangeDetectorRef,
	private eh : ErrorHandlerService
  ) {  }

  ionProgressbar = null
  shouldAddBackLoopAnimation: boolean = false;
  // something to do with animation, ah for not move backwards when starting over
  loopPosition : number = .5
  isLooping : boolean = false
  
  ngOnInit() {


  }

  ngAfterViewInit(){

    this.ps.isLooping$.subscribe(isLooping => {
        console.log('received is looping' )
        this.isLooping = isLooping
        this.changeDetector.detectChanges()
    })

    this.ps.loopTime$.subscribe(loopPosition => {

        try{
            //console.log(loopPosition)
            if(this.shouldAddBackLoopAnimation){
                const ionProgressBarElem : any = document.getElementById('ionProgressBar')
                ionProgressBarElem.classList.remove('m-ion-progress-bar')
                this.shouldAddBackLoopAnimation = false
            }

            if(loopPosition >= 1 || loopPosition < 0 ){
                const ionProgressBarElem : any = document.getElementById('ionProgressBar')
                ionProgressBarElem.classList.add('m-ion-progress-bar')
                this.shouldAddBackLoopAnimation = true
            }
            this.loopPosition = loopPosition
            this.changeDetector.detectChanges()
        } catch (e){
            console.error('Error, loopProgressBar loopTime subg',e)
			this.eh.logSentryError(e)
        }
    })

  }

}
