import { UnsupportedInAppBrowserComponent } from './components/modals/unsupported-in-app-browser/unsupported-in-app-browser.component';
import { TuneListPageModule } from './tune-list/tune-list.module';
import { PwaInstallPromptIosModule } from './components/pwa-install-prompt/ios/pwa-install-prompt-ios.module';
import { MHttpService } from './services/helper-functions/mhttp/mhttp.service'
import { AppStateService } from './services/app-state.service';
import { mCanActivateService } from './services/m-can-activate.service';
import { FooterBarModule } from './components/footer-bar/footer-bar.module';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, Renderer2 } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';
import { ActivatedRouteSnapshot, RouteReuseStrategy} from '@angular/router';
import { MatRadioModule} from '@angular/material/radio';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatSelectModule} from '@angular/material/select';
import { TextFieldModule} from '@angular/cdk/text-field'; 
import { MatInputModule} from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { MatSliderModule} from '@angular/material/slider';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from './app.component';
import { PlayTuneComponent } from './play-tune/play-tune.component';
/* import { EditTuneAdminComponent } from './edit-tune/edit-tune.component'; */
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FunnelComponent } from './funnel/funnel.component';
import {NgSwitch, NgSwitchDefault} from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {MatChipInputEvent} from '@angular/material/chips';
import { MatChipsModule } from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module'
import { FindTunePageModule } from './find-tune/find-tune.module';
import { authHttpInterceptorService } from './services/http-interceptors/auth/auth-http-interceptor.service';
import { MatChipListboxChange } from '@angular/material/chips';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from './services/global.service';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ENV } from '../../environments/environment'
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CommaToDotDirective } from './directives/commaToDot/comma-to-dot.directive';

import { AddTuneToListComponent } from './components/add-tune-to-list/add-tune-to-list.component';
import { RenameTuneListComponent } from './components/modals/rename-tune-list/rename-tune-list.component';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular-ivy";
import { SharedModule } from './shared/shared.module';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { App } from '@capacitor/app';

import {CdkAccordionModule} from '@angular/cdk/accordion';
import { NotRecommendedBrowserComponent } from './components/modals/not-recommended-browser/not-recommended-browser.component';
import { FullscreenSpinnerComponent } from './components/modals/fullscreen-spinner/fullscreen-spinner.component';
import { ReadPrivateSpotListsInfoComponent } from './components/modals/read-private-spot-lists-info/read-private-spot-lists-info.component';
import { IonicStorageModule } from '@ionic/storage-angular';
import { LoggingLocalService } from './services/logging-local-service/logging-local-service.service';

@NgModule({
    declarations: [
        AppComponent,
        /*     EditTuneAdminComponent, */
        //FindTunePage,
        FunnelComponent,
		AddTuneToListComponent,
		RenameTuneListComponent,
		UnsupportedInAppBrowserComponent,
		NotRecommendedBrowserComponent,
		FullscreenSpinnerComponent,
        ReadPrivateSpotListsInfoComponent

    ],
    imports: [
        AngularFireModule.initializeApp(ENV.FIRE_BASE_APP_CONFIG),
        AngularFireMessagingModule,
        // Modules that are inported with also have a decleration array
        // All components above have access to these features
        BrowserModule,
        HttpClientModule,
        //NoopAnimationsModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSelectModule,
        TextFieldModule,
        MatInputModule,
        MatFormFieldModule,
        BrowserAnimationsModule,
        FormsModule,
        MatSnackBarModule,
        MatSliderModule,
        MatTabsModule,
        MatChipsModule,
        PwaInstallPromptIosModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
		SharedModule,
		IonicModule,
		CdkAccordionModule
		

,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: ENV.PRODUCTION,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
	exports:[
		SearchBarComponent
	],
    providers: // services that will be injected in compnents
    [
        CookieService,
        GlobalService,
        mCanActivateService,
        InAppBrowser,
        MHttpService,
        AppStateService,
        LoggingLocalService,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: authHttpInterceptorService,
            multi: true
        },
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
			  showDialog: false,
			}),
		},
        // MAT_RADIO_DEFAULT_OPTIONS,npm install --save @sentry/browser
    ],
    bootstrap: [AppComponent]
})

export class AppModule
{
	constructor() {}
}
 