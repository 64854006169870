import { Injectable } from '@angular/core';
import {HttpClient, HttpParams,HttpHeaders} from '@angular/common/http';
import { MTrackV1, SlimSpotTrackV1, SlimSpotArtistV1, MAlbumV1, TuneTrackV1, MArtistV1, Tune, FadeOptions, ElemType } from './../../classes-enums-interfaces-types/classes/classes';
import { PlayerService } from './player.service';
import { ListElementTypesEnum } from '../../../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient,
    private ps: PlayerService,
  ) { }

  listElementTypes = ListElementTypesEnum;
  spotSearchLimitDefault = 20;

  getTracks = (query: string, offset: number, limit: number) => {

      console.log("Got to getTrack");
      var params: HttpParams = new HttpParams().set('q', query).set('type', "track").set('offset', String(offset)).set('limit', String(limit));
      //params.set('q',query).set('type',"track").set('offset',String(offset)).set('limit',String(limit));

      return this.http.get("https://api.spotify.com/v1/search/", {
          params: params,
          headers: new HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken())
      });

  }

  getTracksById(trackIds: string) {
    //trackIds are comma sepereated List, TODO make input array and convert here
    console.log("Got to getTrackCover");
    return this.http.get("https://api.spotify.com/v1/tracks/?ids=" + trackIds, {
        headers: new HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken())
    });
}

getTrackNameArtistAndCover(tune) {
    console.log("getTrackNameAndArtistName");
    return this.http.get("https://api.spotify.com/v1/tracks/" + tune.slimTuneTrack.trackId,
        { headers: new HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()) }
    );
}

spotFetchFormatToMFormat(spotArr, itemsAddedAt?:string[]): any[] {
    var mFormatArr: object[] = [];
    for (const [i,spotElem ]of spotArr.entries()) {
        switch (spotElem.type) {
            case 'track':
				let mTrack
				if(itemsAddedAt){
					 mTrack = this.spotTrackToMSlimSpotTrack(spotArr[i], itemsAddedAt[i])
				} else {
					 mTrack = this.spotTrackToMSlimSpotTrack(spotArr[i])
				}
				if(this.mFormatHasFields(mTrack))
                	mFormatArr.push(mTrack);
                break;
            case 'artist':
				const mArtist = this.spotArtistToMSlimSpotArtist(spotElem)
				if(this.mFormatHasFields(mArtist))
                	mFormatArr.push(mArtist)
				break;
            default:
                break;
        }
    }
    return mFormatArr;
}
mFormatHasFields(elem : SlimSpotTrackV1 | SlimSpotArtistV1){
	
	// Some artists returned from Spot API has images as empty array
	// Add more checks when more bugs are discovered
	if(elem.images && (elem?.images.length >= 3)){
		return true
	} else {
		return false
	}

	if (elem.type == ListElementTypesEnum.slimSpotTrack){
		
	} else if (elem.type == ListElementTypesEnum.slimSpotTrack){
	
	}
}

//Fix, just unnessary fetch
//no reason to do on FE
spotTrackToMTrack(spotTrack) {

    let mArtists = []
    spotTrack.artists.forEach(artist => {
        mArtists.push(
            new MArtistV1(
                artist.id,
                artist.name,
                [],
                ListElementTypesEnum.slimSpotArtist)
        )
    })

    const mTrack = new MTrackV1(
        new MAlbumV1(
            spotTrack.album.id,
            spotTrack.album.images, // 3 images
        ),
        mArtists,
        spotTrack.available_markets,
        spotTrack.durationMs ,
        spotTrack.id,
        '',
        0,
        spotTrack.name
    )

    mArtists.push()

    return mTrack

}

spotTrackToMSlimSpotTrack(spotTrack, addedAt? : string): SlimSpotTrackV1 {

    let slimSpotTrack: SlimSpotTrackV1 = new SlimSpotTrackV1(
        spotTrack.artists[0].id,
        spotTrack.artists[0].name,
        spotTrack.duration_ms,
        spotTrack.album.images,
        spotTrack.id,
        spotTrack.name,
		spotTrack.addedAt
    )

    return slimSpotTrack;
}

spotArtistToMSlimSpotArtist(spotArtist): SlimSpotArtistV1 {
    let slimSpotArtist: SlimSpotArtistV1 = new SlimSpotArtistV1(
        spotArtist.id,
        spotArtist.images,
        spotArtist.name,
        ListElementTypesEnum.slimSpotArtist

    )
    return slimSpotArtist;
}

search(searchWord: string, type:any, offset: number, limit: number = this.spotSearchLimitDefault) : Observable<any>{

    const params = new HttpParams()
        .set('q', searchWord)
        .set('type', type)
        .set('limit', limit.toString())
        .set('offset', offset.toString())
		.set('market','SE')

	// dont know what res will be if no results found	
    return this.http.get('https://api.spotify.com/v1/search',
        {
            headers: new HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()),
            params
        },

    );
}


getDevices() {
    return this.http.get
        (
            "https://api.spotify.com/v1/me/player/devices",
            { headers: new HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()) }
        );

}

getPlaylist(playListId, offset, sectionLength) {
    console.log("getPlayList");
    console.log(playListId);
    console.log(offset)
    return this.http.get("https://api.spotify.com/v1/playlists/" + playListId + "/tracks?offset=" + offset + "&limit=" + sectionLength + "&fields=items(track(name,duration_ms,artists(name),album(images(url)),id))",
        {
            headers: new HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken())
        }
    );
}

getLikedSongsList(offset, limit){
	return this.http.get("https://api.spotify.com/v1/me/tracks?offset=" + offset + "&limit=" + limit,
	{
		headers: new HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken())
	}
);
}

getTrackImg(trackId) { }

addTrackToPlaylist(plId: string, trackId: string) {

    return this.http.post("https://api.spotify.com/v1/playlists/" + plId + "/tracks",
        {
            "uris": ["spotify:track:" + trackId]
        },
        {
            headers: new HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken())
        }
    );
}

deleteTrackFromPlaylist(plId: string, trackId: string) {

    const options =
    {
        headers: new HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()),
        body:
        {
            "tracks":
                [
                    {
                        "uri": "spotify:track:" + trackId
                    }
                ]
        }
    }

    return this.http.delete("https://api.spotify.com/v1/playlists/" + plId + "/tracks", options);

}

getUserSpotPlaylists(paginationPlaylistOffset : number, limit : number = 10) : Observable<any>{
		const params = new HttpParams()
		.set('offset', paginationPlaylistOffset.toString())
		.set('limit',limit.toString())

		return this.http.get
			(
				"https://api.spotify.com/v1/me/playlists",
				{ 
					headers: new HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()),
					params 
				}
			);
	
	}

	getSpotPlaylist(playlistId : string, offset : number, limit : number = 10) : Observable<any>{
		const params = new HttpParams()
		.set('offset', offset.toString())
		.set('limit', limit.toString())

		return this.http.get
			(
				`https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
				{ 
					headers: new HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()),
					params 
				}
			);
	
	}

	getUserLikedSongs(offset : number, limit : number = 10) : Observable<any>{
		const params = new HttpParams()
		.set('offset', offset.toString())
		.set('limit', limit.toString())

		return this.http.get
			(
				`https://api.spotify.com/v1/me/tracks`,
				{ 
					headers: new HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()),
					params 
				}
			);
	
	}

}
