import { AuthService } from './../../auth/auth.service';
import { Router } from '@angular/router';
import { MHttpError } from '../../../classes-enums-interfaces-types/classes/classes.js';
import { HttpClient,  } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Capacitor } from "@capacitor/core"
import { Http,HttpResponse, HttpParams,HttpHeaders, HttpOptions } from "@capacitor-community/http"
import {from, Observable, throwError} from 'rxjs'
import { map, catchError } from 'rxjs/operators';
import { ObserversModule } from '@angular/cdk/observers';
import { ENV } from '../../../../../environments/environment';

export type MHttpImpOptions =  Pick<MHttpOptions, "params"> | Pick<MHttpOptions, "sendStatus"> | MHttpOptions | null

export interface MHttpReturn<T> {
    data : T,
    status?:number
}

interface MHttpOptions {
    params: HttpParams,
    sendStatus : boolean | undefined
}

@Injectable({
    providedIn: 'root'
})

export class MHttpService {

    // only reason for using obs instead of plain promise is to learn and be 
    // more stream compatible later, and also easier conversion
    // #OPT This overloading must be possible to do better with more TS knowledge, e.g don't use any

  constructor(
    private http:HttpClient,
    private router: Router,
    private injector: Injector
  ) { }

    first401 : boolean = true

    get<T>(url:string) : Observable<T>
    get<T>(url:string, options? : Pick<MHttpOptions, "params">) : Observable<T>
    get<T>(url:string, options? : Pick<MHttpOptions, "sendStatus">) : Observable<MHttpReturn<T> >
    get<T>(url:string, options? : MHttpOptions) : Observable<MHttpReturn<T> >
    get<T>(url:string, options? : MHttpOptions) : Observable<T | MHttpReturn<T>> {
    //(Observable<T> | Observable<MHttpReturn<T>>) {

        let obs$ : Observable<any>

        const httpOptions : HttpOptions = {
            url : url,
            method:'GET',
            webFetchExtra: {
                credentials:'include'
            }
        }

        if(options && "params" in options){
            httpOptions.params = options.params
        }


        /*obs$ = from(Http.get({url:url}))
            .pipe(
                map(res => {
                    if(res.status > 299){
                        //#FIX
                        throw Error(
                            `status: ${res.status}
                            error: ${res.data}`
                        )
                    }
                    return res.data
                })
        ) */


        /*try{
                const data = await Http.get(httpOptions)
                subscriber.next(data)
            } catch(e){
                subscriber.error(e)
            } */
        

        /*obs$ = from(
            Http.get({url:url})).pipe(
                map(res => {
                    if(res.status > 299){
                        //#FIX
                        throwError('innerError')
                    } else{
                        return res.data
                    }
                })
        ) */

        //Http.get()

        if(options && "params" in options && options?.params){
            httpOptions.params = options.params
        }
    //}

        if(options && "sendStatus" in options && options?.sendStatus){
        /* obs$ = new Observable( (subsriber) => {
            let tee : any
            subsriber.next({
                status:1,
                data:tee
            })
        }) */
        obs$ = this.getHttp$<T>(httpOptions,true)
        }else {
            obs$ = this.getHttp$<T>(httpOptions)
            /* obs$ = new Observable( (subsriber) => {
                let tee : any
                subsriber.next(tee)
            }) */
        }

        return obs$

    }
    
    //post<T>(url:string, httpOptions:HttpPostOptions) : Observable<T>
    post<T>(url:string, body: object, options? : Pick<MHttpOptions, "params">) : Observable<T>
    post<T>(url:string, body: object, options? : Pick<MHttpOptions, "sendStatus">) : Observable<MHttpReturn<T> >
    post<T>(url:string, body: object, options? : MHttpOptions) : Observable<MHttpReturn<T> >
    //ost<T>(url:string, httpOptions:HttpPostOptions, mOptions:MHttpOptions) : 
    //post<T>(url:string,body:object, mOptions:MHttpOptions, params:HttpParams) : MHttpReturn<T> 
    //post<T>(url:string, body: object, options:MHttpPostOptions) = Observable< options.sendStatus ? T : MHttpReturn<T>> 
    post<T>(url:string, body: object, options:MHttpImpOptions = null) : Observable<T | MHttpReturn<T>>  {
        let obs$ : Observable<any>

        const httpOptions : HttpOptions = {
            url : url,
            data: body,
            method:'POST',
            headers: {'Content-Type': 'application/json'},
            webFetchExtra : {
                credentials:'include'
            }
        }
        //if(typeof options !== null){
        //if(options){
            if(options && "params" in options){
                httpOptions.params = options.params
            }
        //}

        if(options && "sendStatus" in options){
            /* obs$ = new Observable( (subsriber) => {
                let tee : any
                subsriber.next({
                    status:1,
                    data:tee
                })
            }) */
            obs$ = this.getHttp$<T>(httpOptions,true)
        }else {
            obs$ = this.getHttp$<T>(httpOptions)
            /* obs$ = new Observable( (subsriber) => {
                let tee : any
                subsriber.next(tee)
            }) */
        }

        return obs$
    }

    patch<T>(url:string,body:object,params?:HttpParams) : Observable<T>
    patch<T>(url:string,body:object,params?:HttpParams) : Observable<MHttpReturn<T>> {
        let obs$ : Observable<any>

        const httpOptions : HttpOptions = {
            url : url,
            method:'PATCH',
            data: body,
            headers: {'Content-Type': 'application/json'},
            webFetchExtra: {
                credentials:'include'
            }
        }

        if(params){
            httpOptions.params = params
        }

        obs$ = this.getHttp$<T>(httpOptions)

        return obs$
    }

    getHttp$<T>(httpOptions:HttpOptions) : Observable<T>
    getHttp$<T>(httpOptions:HttpOptions, sendStatus : boolean ) : Observable<MHttpReturn<T>>
    getHttp$<T>(httpOptions:HttpOptions, sendStatus? : boolean ) : Observable<T | MHttpReturn<T>>{
        return new Observable( (subscriber) => {
            Http.request(httpOptions).then(res => {
				if ( !ENV.DEBUG && res.status == 401){
                    if(this.first401){
                        this.first401 = false
                        //To avoid Circular Dependency
                        this.injector.get(AuthService).login({redirectPath:this.router.url})
                    } else {
                        this.first401 = true
                        this.router.navigateByUrl('/login')
                    }
                }
                else if(res.status>299){
                    subscriber.error( {
                        status:res.status,
                        message:res.data
                    })
                } else {
                    if(sendStatus){
                        subscriber.next({
                            status:res.status,
                            data:res.data 
                        })
                    } else {
                        subscriber.next(res.data)
                    }
                    //#Fix if want to use data streams
                }

                subscriber.complete()
            }).catch(e => {
                subscriber.error(e)
            })
        })
    }

    
}
