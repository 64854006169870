import { FormBuilder } from '@angular/forms'
import { ListElement } from './../classes-enums-interfaces-types/types/types'
import { mCanActivateService } from './../services/m-can-activate.service'
import { RenameTuneListComponent } from './../components/modals/rename-tune-list/rename-tune-list.component'
import {
    ListElementHtmlContextE,
    LoggingLocalRoutingEvents,
    ModalsE,
    MyLibrarySwtichSegment,
    MyLibraryTuneListStates,
    ToastEnum,
} from './../classes-enums-interfaces-types/enums/enums.enum'
import { GlobalService } from './../services/global.service'
import { MHttpService } from './../services/helper-functions/mhttp/mhttp.service'
import { SaveSendAction } from '../components/footer-bar/footer-bar.module'
import { PlayerService } from './../services/music/player.service'
import { SearchHandlerComponent } from '../find-tune/search-handler/search-handler.component'
import { SearchHandlerModule } from '../find-tune/search-handler/search-handler.module'
import { FindTuneStateService } from '../services/states/find-tune/find-tune-state.service'
import { HttpParams, HttpClient, HttpResponse } from '@angular/common/http'
import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewChildren,
    QueryList,
    ChangeDetectorRef,
    NgZone,
    AfterViewInit,
} from '@angular/core'
import {
    Router,
    ActivatedRoute,
    ParamMap,
    RouterOutlet,
    ActivationStart,
} from '@angular/router'
import { ENV } from '../../../environments/environment'
import { ITune } from '../classes-enums-interfaces-types/interfaces/list-elems/list-elems.model'
import { catchError, concatMap } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs'
import { FacebookDataService } from '../services/facebook-data/facebook-data.service'
import { SegmentStatesEnum } from '../classes-enums-interfaces-types/enums/find-tune/enums.enum'
import { MyMetaService } from '../services/my-meta/my-meta.service'
import { NgNavigatorShareService } from 'ng-navigator-share'
import { env } from 'process'
import { SEOService } from '../services/seo/seo.service'
import { ListElementComponent } from '../components/list-element/list-element.component'
import {
    TriggerSlim,
    Tune,
} from '../classes-enums-interfaces-types/classes/classes'
import * as _ from 'lodash'
import {
    AlertController,
    IonAccordionGroup,
    IonPopover,
    MenuController,
    ModalController,
} from '@ionic/angular'
import { CookieService } from 'ngx-cookie-service'
import {
    ITriggerFE,
    ITuneList,
    ITuneListByNumberResult,
    ITuneListPop,
    ITuneListPopLean,
    ITuneReorder,
    UsersTuneListM,
} from '../../../../backend/classes-enums-interfaces-types/interfaces/interfaces.shared'
import { MyServerService } from '../services/my-server/my-server.service'
import { Location } from '@angular/common'
import { MyLibraryStateService } from '../services/states/my-library-state.service'
import { SearchBarComponent } from '../components/search-bar/search-bar.component'
import { CdkAccordionItem, CdkAccordion } from '@angular/cdk/accordion'
import { CanShareResult, Share } from '@capacitor/share'
import { ResultAsync } from 'neverthrow'
import { ErrorHandlerService } from '../services/error-handler/error-handler.service'
import { IonReorderGroup } from '@ionic/angular'
import { TuneListPlayerService } from '../services/tune-list-player/tune-list-player.service'
import { mGlobal } from '../mglobal'
import { LoggingLocalService } from '../services/logging-local-service/logging-local-service.service'

// Potentail leaner code improvements
// Move back forward bar up to search component and use some form of ng-content
// structure to render custom templates/components from child components to
// only have one backforward bar.

@Component({
    selector: 'app-my-library',
    templateUrl: './my-library.page.html',
    styleUrls: ['./my-library.page.scss'],
})
export class MyLibraryPage implements OnInit, AfterViewInit {
    triggersFetchOffset: number = 0
    triggersFetchLimit: number = 20
    allTriggersFetched: boolean = false

    //@ViewChild("searchField") searchField : IonInput;

    constructor(
        public findTuneState: FindTuneStateService,
        private router: Router,
        public mShare: NgNavigatorShareService,
        private ps: PlayerService,
        private gs: GlobalService,
        private menuCtrl: MenuController,
        private mServer: MyServerService,
        private location: Location,
        private alertController: AlertController,
        private modalCtrl: ModalController,
        private myLibStateService: MyLibraryStateService,
        private changeDetector: ChangeDetectorRef,
        private mCanActivate: mCanActivateService,
        private eh: ErrorHandlerService,
        private tuneListPlayer: TuneListPlayerService,
        private loggingLocal: LoggingLocalService,
    ) {}
    //@ViewChildren(ElementContainerHomeComponent) searchEleContArr: QueryList<SearchElementContainerComponent>;

    @ViewChild(IonPopover) ionPop: IonPopover
    @ViewChild(RouterOutlet) outlet: RouterOutlet
    @ViewChild('accordionItem1') cdkAccordionItem1: CdkAccordionItem
    @ViewChild('accordionItem2') cdkAccordionItem2: CdkAccordionItem
    @ViewChild(IonReorderGroup, { static: false }) reorderGroup //: IonReorderGroup;

    customMenu = '../../../assets/menu.svg'
    customFunnel = '../../../assets/funnel-outline.svg'

    selectedSegmentIndex = 0

    segmentContainer

    isTuneSelected = false

    selectedTune

    saveSendAction = SaveSendAction

    tuneFilter = {
        created: true,
        saved: true,
    }

    testBool = true

    infScrollDisabled: boolean = false

    customBackIcon = './assets/arrow-back-outline.svg'
    switchSegment: MyLibrarySwtichSegment = MyLibrarySwtichSegment.tuneTriggers
    switchStatePrev: MyLibrarySwtichSegment = null
    public switchSegmentEnum = MyLibrarySwtichSegment

    public tuneListStatesEnum = MyLibraryTuneListStates
    tuneListState = MyLibraryTuneListStates.multipleTuneLists

    tuneList = {
        listLean: <ITuneListPopLean>null,
        listRes: <ITuneListByNumberResult>null,
        fetchOffset: <number>0,
        fetchLimit: <number>15,
        allTunesFetched: <boolean>false,
        missingOnServer: <boolean>false,
    }

    tuneListDefault = {
        listLean: <ITuneListPopLean>null,
        listRes: <ITuneListByNumberResult>null,
        fetchOffset: <number>0,
        fetchLimit: <number>15,
        allTunesFetched: <boolean>false,
        missingOnServer: <boolean>false,
    }

    tuneListMSearch = {
        fetchOffset: <number>0,
        fetchLimit: <number>15,
        allListMFetched: <boolean>false,
        noListMFound: <boolean>false,
        tuneListM: <ITuneListPopLean[]>[],
        searchDone: <boolean>false,
    }

    tuneListMSearchDefault = {
        fetchOffset: <number>0,
        fetchLimit: <number>15,
        allListMFetched: <boolean>false,
        noListMFound: <boolean>false,
        tuneListM: <ITuneListPopLean[]>[],
        searchDone: <boolean>false,
    }

    tuneListMCreated: ITuneListPopLean[] = null
    tuneListMSaved: ITuneListPopLean[] = null
    tuneListMCreatedOrig: ITuneListPopLean[] = null
    tuneListMSavedOrig: ITuneListPopLean[] = null

    tuneListMLoaded = false
    triggersLoaded = false
    triggers: ITriggerFE[] = [] // for gui can be sorted and filtered
    triggersOrig: ITriggerFE[] = [] // for search

    searchEntered = {
        triggers: false,
        tuneLists: false,
    }

    modalsE = ModalsE

    updatedListName: string

    alertButtons = ['Action']

    downArrowHead = '../../assets/down-outline.svg'
    upArrowHead = '../../assets/up-outline.svg'
    threeDotsIcon = '../../assets/ellipsis-vertical.svg'
    reorderIcon = '../../assets/reorder3.svg'
    closeCircleIcon = '../../assets/close-circle.svg'
    shuffleIcon = '../../assets/shuffle.svg'

    userDisplayName

    currentSearchText

    // the id offset to be sent to be for more search hits iot skip past the ids already returned
    searchTuneIdOffset: string

    ListElementHtmlContextE = ListElementHtmlContextE

    reorderTuneListActive: boolean = false
    tuneListBeforeReorder
    tuneListOrderChanged: boolean = false // manually resetting order still counts as change for simplicity
    shuffleActive: boolean = false

    async share() {}

    backClick() {}

    triggerAnim: Function

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        //this.reorderGroup = document.querySelector('ion-reorder-group');
        //this.fb.injectMessengerExtensionHtmlCode();

        this.mCanActivate.freshSpotTokenFetched$.subscribe(
            (data: { displayName: string }) => {
                this.userDisplayName = data.displayName
            }
        )

        this.ps.selectedTune$.subscribe((selectedTune) => {
            this.selectedTune = selectedTune
            if (selectedTune) this.isTuneSelected = true
        })

        this.tuneListPlayer.shuffleActive$.subscribe((shuffleActive) => {
            this.shuffleActive = shuffleActive
        })
    }

    segmentChanged(e) {
        console.log('segmentChanged')
        this.switchStatePrev = this.switchSegment
        this.switchSegment = e.detail.value
        if (
            this.switchSegment == MyLibrarySwtichSegment.tuneLists &&
            this.tuneListMCreated == null
        ) {
            this.mServer.getUserTuneLists().subscribe(
                (userTuneListM: UsersTuneListM) => {
                    this.tuneListMLoaded = true

                    this.tuneListMCreatedOrig = userTuneListM.tuneListMCreated
                    this.tuneListMCreated = userTuneListM.tuneListMCreated

                    this.tuneListMSavedOrig = userTuneListM.tuneListMSaved
                    this.tuneListMSaved = userTuneListM.tuneListMSaved
                },
                (e) => {
                    console.error(`Error getting user tune lists`, e)
                    this.eh.logSentryError(e)
                    this.tuneListMLoaded = true
                }
            )
        } else if (this.switchSegment == MyLibrarySwtichSegment.tuneTriggers) {
            this.tuneListPlayer.setTuneListPlayerEnabled(false)
        }
    }

    ionViewDidEnter() {
        
        console.log(this.gs.mRouterEventParms.pendingHistoryBackClick)
        //if(!this.gs.mRouterEventParms.pendingHistoryBackClick){
        console.log('my-lib willEnter reload')
        if (this.switchSegment == MyLibrarySwtichSegment.tuneTriggers) {

            this.triggers = []
            this.triggersOrig = []
            this.triggersLoaded = false
            this.triggersFetchOffset = 0
            this.allTriggersFetched = false

            this.getTriggers()
        } else if (this.switchSegment == MyLibrarySwtichSegment.tuneLists) {
            this.mServer.getUserTuneLists().subscribe(
                (userTuneListM: UsersTuneListM) => {
                    this.tuneListMCreated = userTuneListM?.tuneListMCreated
                    this.tuneListMSaved = userTuneListM?.tuneListMSaved
                    this.tuneListMLoaded = true
                },
                (e) => {
                    this.tuneListMLoaded = true
                    this.eh.logSentryError(e)
                    console.error(`Error getting user tune lists`, e)
                }
            )
        }

        this.router.events.subscribe((e) => {
            if (
                e instanceof ActivationStart &&
                e.snapshot.outlet === 'my-library'
            ) {
                // 2T ?
                //this.outlet.deactivate();
            }
        })
        //}

        this.gs.setPendingHistoryBackClick(false)

        if(mGlobal.logLocal){
            this.loggingLocal.addLog(LoggingLocalRoutingEvents.ReachedSignUpPage)
        }
    }

    logForm() {}

    filterButtonClick() {
        this.menuCtrl.open('lib-filter-menu')
    }

    menuButtonClick() {
        this.gs.requestSideMenuToggle(true)
    }

    triggerClick(tune) {
        this.ps.loopTuneRequest(tune)
        // if(this.selectedTune == tune && tune){
        // 	this.ps.pauseTrack()
        // this.mServer.getTrackHasMultipleTunes(this.selectedTune.slimTuneTrack.trackId).subscribe( (hasMultipleTunes : boolean) => {

        // 	let redirectRoute
        // 	if(hasMultipleTunes){
        // 		redirectRoute = '/track'
        // 	} else {
        // 		redirectRoute = '/edit-tune'
        // 	}

        // 	this.ngZone.run(() => {
        // 		this.router.navigateByUrl(redirectRoute,{ state: {slimTuneTrack : this.selectedTune.slimTuneTrack}})
        // 	})

        // }, e => {
        // 	console.log('error getting track has multiple tunes',e)
        // 	this.eh.logSentryError(e)
        // })
        //} else {
        // }
        //const tune = this.getTuneFromTrigger(trigger)
        //this.ps.nextLoopItr(1)
    }

    listElemClick(tune, index: number) {
        if (this.switchSegment == this.switchSegmentEnum.tuneLists) {
            if (
                this.tuneListPlayer.tuneListNumber !=
                this.tuneList.listLean.number
            ) {
                const tunes: Tune[] = this.tuneList.listRes.tunesReorder.map(
                    (tuneReorder) => tuneReorder.tune
                )
                this.tuneListPlayer.setTunes(
                    tunes,
                    this.tuneList.listLean.number,
                    true
                )
            }
            this.tuneListPlayer.setTuneIndex(index)
        }

        this.ps.loopTuneRequest(tune)
        /*  if(this.selectedTune == tune && tune){
        	this.ps.pauseTrack()
        	this.mServer.getTrackHasMultipleTunes(this.selectedTune.slimTuneTrack.trackId).subscribe( (hasMultipleTunes : boolean) => {

        		let redirectRoute
        		if(hasMultipleTunes){
        			redirectRoute = '/track'
        		} else {
        			redirectRoute = '/edit-tune'
        		}

        		this.ngZone.run(() => {
        			this.router.navigateByUrl(redirectRoute,{ state: {slimTuneTrack : this.selectedTune.slimTuneTrack}})
        		})

        	}, e => {
        		console.log('error getting track has multiple tunes',e)
        		this.eh.logSentryError(e)
        	})
        } else {
        	this.ps.loopTuneRequest(tune)
        } */
        // //const tune = this.getTuneFromTrigger(trigger)
        // //this.ps.nextLoopItr(1)
    }

    getTuneFromTrigger(trigger): Tune {
        return trigger.autoCompletes[0].tunes[0].tune
    }

    // Fix Tune vs TuneV3
    // #opt Dont reload list each time it is clicked
    tuneListClicked(tuneList: ITuneListPopLean, event) {
        //#opt
        if (!event?.target?.id.includes('option-button')) {
            this.tuneList.listRes = null

            this.tuneList.listLean = tuneList
            this.tuneList.fetchOffset = 0
            this.tuneList.allTunesFetched = false

            this.tuneList.missingOnServer = false

            this.tuneListState = MyLibraryTuneListStates.singleTuneList

            this.mServer
                .getTuneListByNumber(
                    this.tuneList.listLean.number,
                    this.tuneList.fetchOffset,
                    this.tuneList.fetchLimit,
                    true
                )
                .subscribe((res: HttpResponse<ITuneListByNumberResult>) => {
                    if (res.status == 204) {
                        this.tuneList.missingOnServer = true
                    } else {
                        if (res.body?.tunesReorder.length < 1) {
                            this.tuneList.allTunesFetched = true
                        }

                        this.tuneList.listRes = res?.body

                        this.tuneList.fetchOffset +=
                            res.body?.tunesReorder.length
                    }

                    this.changeDetector.detectChanges()
                }),
                (e) => {
                    console.log('getTuneListByNumber Error')
                    console.log(e)
                    this.eh.logSentryError(e)
                }
        }
    }

    endOfScrollTuneList(event) {
        // #opt will only fetch tuens when offset is different from 0
        this.mServer
            .getTuneListByNumber(
                this.tuneList.listLean.number,
                this.tuneList.fetchOffset,
                this.tuneList.fetchLimit
            )
            .subscribe((res: ITuneListByNumberResult) => {
                // #opt could check if less is received then asked but some tunes might be corrupted and filter out,
                // now there will be an extra scroll in gui in find out
                if (res.tunesReorder.length < 1) {
                    this.tuneList.allTunesFetched = true
                } else {
                    this.tuneList.listRes.tunesReorder =
                        this.tuneList.listRes.tunesReorder.concat(
                            res.tunesReorder
                        )
                    this.tuneList.fetchOffset += res.tunesReorder.length

                    const tunes = this.tuneList.listRes.tunesReorder.map(
                        (tuneReorder) => tuneReorder.tune
                    )
                    this.tuneListPlayer.appendToTunes(tunes)
                }

                event.target.complete()
                this.changeDetector.detectChanges()
            }),
            (e) => {
                console.log('getTuneListByNumber Error')
                console.log(e)
                this.eh.logSentryError(e)
                event.target.complete()
            }
    }

    // combat with tune
    // editTUne on enter

    //[(ngModel)] nor [(checked)] do seem to work
    updateTuneFilter(e) {
        e = e.detail
        if (e.value == 'createdTunesCB') {
            this.tuneFilter.created = e.checked
        } else if (e.value == 'savedTunesCB') {
            this.tuneFilter.saved = e.checked
        }

        if (this.tuneFilter.created && !this.tuneFilter.saved) {
        } else if (!this.tuneFilter.created && this.tuneFilter.saved) {
        } else {
            //#opt sort
        }
    }

    backClickMyLib() {
        this.tuneListPlayer.setTuneListPlayerEnabled(false)

        if (this.switchSegment == MyLibrarySwtichSegment.tuneTriggers) {
            if (this.switchStatePrev == MyLibrarySwtichSegment.tuneLists) {
                this.switchSegment = MyLibrarySwtichSegment.tuneLists
                this.switchStatePrev = null
            } else {
                this.hardBack()
            }
        } else if (this.switchSegment == MyLibrarySwtichSegment.tuneLists) {
            if (this.tuneListState == MyLibraryTuneListStates.singleTuneList) {
                this.tuneListState = MyLibraryTuneListStates.multipleTuneLists
            } else if (
                this.tuneListState == MyLibraryTuneListStates.multipleTuneLists
            ) {
                if (
                    this.switchStatePrev == MyLibrarySwtichSegment.tuneTriggers
                ) {
                    this.switchSegment = MyLibrarySwtichSegment.tuneTriggers
                    this.switchStatePrev = null
                } else {
                    this.hardBack()
                }
            }
        }
    }

    removeTuneFromTuneListClick(tuneReorder: ITuneReorder) {
        this.mServer
            .removeTuneFromTuneList(
                this.tuneList.listLean._id.toString(),
                tuneReorder.tune._id
            )
            .subscribe(
                (
                    tuneList: any //fix
                ) => {
                    this.tuneList.listRes.tunesReorder =
                        this.tuneList.listRes.tunesReorder.filter(
                            (tuneReorderInList) =>
                                tuneReorderInList.tune._id !==
                                    tuneReorder.tune._id &&
                                tuneReorderInList?.tune._id
                        )
                    this.changeDetector.detectChanges()
                },
                (e) => {
                    console.error('Error removing tune from tune list', e)
                    this.eh.logSentryError(e)
                }
            )
        console.log('removeTuneFromTuneListClick')
    }

    /*addedTuneToTuneListClick(addedTuneData){

		if(this.tuneListMCreated){
			// no point in adding if I haven't fetched the tune lists yet
			if(addedTuneData?.addTuneToListData?.createdList){
				this.tuneListMCreated.push(addedTuneData.addTuneToListData.tuneList)
				this.changeDetector.detectChanges()
				return
			} else{
				this.tuneListMCreated.forEach(tuneList => {
					if(tuneList._id == addedTuneData?.addTuneToListData?.tuneList?._id && tuneList?._id){
						
						tuneList.tunes.push(addedTuneData.tune)

						this.changeDetector.detectChanges()
						return
					}
				});
			}
		}
	} */

    removeTriggerClick(triggerToRemove) {
        this.mServer.removeTrigger(triggerToRemove._id).subscribe(
            (res) => {
                console.log('Tune removed')
                this.gs.showToast({ msg: 'Tune removed' })
                this.triggers = this.triggers.filter(
                    (trigger) =>
                        trigger._id !== triggerToRemove._id && trigger._id
                )
            },
            (e) => {
                console.error('Error deleting tune list', e)
                this.eh.logSentryError(e)
            }
        )
    }

    async shareTuneListClick(tuneList) {
        try {
            const shareUrl = `${ENV.DOMAIN_OF_FRONTEND}/tune-list?list=${tuneList.number}`
            const shareObj = {
                title: 'Tune it',
                url: shareUrl,
                text: `Tune it - Music GIFs\nOpen Tune list here\n\n`,
                dialogTitle: 'Select where to share the Tune',
            }
            const canShare: boolean = (await Share.canShare())?.value

            if (canShare) {
                await Share.share(shareObj)
            }
        } catch (e) {
            console.error('Error sharing Tune list', e)
            this.gs.showToast({
                msg: 'Error sharing Tune list',
                type: ToastEnum.error,
            })
            this.eh.logSentryError(e)
        }
    }

    async renameTuneListClick(tuneListToRename) {
        const modal = await this.modalCtrl.create({
            component: RenameTuneListComponent,
            cssClass: '.rename-tune-list-modal',
            id: ModalsE.renameTuneList,
            htmlAttributes: { style: '--backdrop-opacity:0.5' },
        })

        modal.present()

        let updatedTuneListName
        modal.onWillDismiss().then((res) => {
            if (res.role === 'confirm') {
                updatedTuneListName = res.data
                this.mServer
                    .renameTuneList(
                        tuneListToRename._id.toString(),
                        updatedTuneListName
                    )
                    .subscribe(
                        (res) => {
                            this.tuneListMCreated = this.tuneListMCreated.map(
                                (tuneList) => {
                                    if (
                                        tuneList._id == tuneListToRename._id &&
                                        tuneList?._id
                                    ) {
                                        tuneList.name = updatedTuneListName
                                    }
                                    return tuneList
                                }
                            )
                        },
                        (e) => {
                            console.error('Error renaming tune list', e)
                            this.eh.logSentryError(e)
                        }
                    )
            }
        })
    }

    async removeTuneListClick(tuneListToRemove) {
        const alert = await this.alertController.create({
            header: 'Confirm',
            message: `"${tuneListToRemove.name}"\n removal`,
            cssClass: 'delete-list-alert',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'alert-delete-list-cancel-button',
                },
                {
                    text: 'Remove',
                    role: 'destructive',
                    cssClass: 'm-alert-cancel-button',
                    handler: () => {
                        if (
                            tuneListToRemove?.createdBy?.displayName ==
                                this.gs.getUserDisplayName() &&
                            tuneListToRemove?.createdBy
                        ) {
                            this.mServer
                                .removeCreatedTuneList(
                                    tuneListToRemove._id.toString()
                                )
                                .subscribe(
                                    (res) => {
                                        this.tuneListMCreated =
                                            this.tuneListMCreated.filter(
                                                (tuneList) =>
                                                    tuneList._id !==
                                                    tuneListToRemove._id
                                            )
                                    },
                                    (e) => {
                                        console.error(
                                            'Error removving created Tune list',
                                            e
                                        )
                                        this.eh.logSentryError(e)
                                    }
                                )
                        } else {
                            this.mServer
                                .removeSavedTuneList(
                                    tuneListToRemove._id.toString()
                                )
                                .subscribe(
                                    (res) => {
                                        this.tuneListMSaved =
                                            this.tuneListMSaved.filter(
                                                (tuneList) =>
                                                    tuneList._id !==
                                                    tuneListToRemove._id
                                            )
                                    },
                                    (e) => {
                                        console.error(
                                            'Error removing saved Tune list',
                                            e
                                        )
                                        this.eh.logSentryError(e)
                                    }
                                )
                        }
                    },
                },
            ],
        })

        alert.present()
    }

    hardBack() {
        //this.ps.pauseTrack()
        const hasHistory = this.router.navigated
        if (hasHistory) this.location.back()
    }

    async clickCreateTuneList() {}

    onWillDismiss(event) {
        console.log('onWillDismiss')
    }

    searchBarSearchEntered(searchText: string) {
        if (searchText != '') {
            this.currentSearchText = searchText

            if (this.switchSegment == MyLibrarySwtichSegment.tuneTriggers) {
                if (!this.searchEntered.triggers) {
                    this.triggers = []
                }
                this.searchEntered.triggers = true

                this.mServer
                    .getUsersTriggersFromSearch(
                        searchText,
                        this.searchTuneIdOffset
                    )
                    .subscribe((res) => {
                        this.triggers = this.triggers.concat(res.triggers)
                        this.searchTuneIdOffset = res.offsetTuneId
                        this.allTriggersFetched = res.allTriggersFetched
                    })
            } else if (this.switchSegment == MyLibrarySwtichSegment.tuneLists) {
                this.tuneListState = MyLibraryTuneListStates.multipleTuneLists
                this.searchEntered.tuneLists = true

                this.tuneListMSearch = _.cloneDeep(this.tuneListMSearchDefault)

                // #opt no need to reoload if already have been at the segment tab
                this.mServer
                    .getTuneListsFromSearch(
                        searchText,
                        this.tuneListMSearch.fetchOffset,
                        this.tuneListMSearch.fetchLimit,
                        true
                    )

                    .subscribe((res: HttpResponse<ITuneListPopLean[]>) => {
                        this.tuneListMSearch.searchDone = true
                        if (res.status == 204) {
                            this.tuneListMSearch.noListMFound = true
                        } else {
                            if (res.body?.length < 1) {
                                this.tuneListMSearch.allListMFetched = true
                            }

                            this.tuneListMSearch.fetchOffset += res.body?.length

                            this.tuneListMSearch.tuneListM = res.body
                        }

                        this.changeDetector.detectChanges()
                    }),
                    (e) => {
                        this.tuneListMSearch.searchDone = true
                        console.log('getTuneListMFromSearch Error')
                        console.log(e)
                        this.eh.logSentryError(e)
                    }
            }

            /*const listSavedFiltered = []
                const listCreatedFiltered = []

                for (const tuneList of this.tuneListMCreated) {
                    if (
                        tuneList.name
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                    ) {
                        listCreatedFiltered.push(tuneList)
                    }
                }

                for (const tuneList of this.tuneListMSaved) {
                    if (
                        tuneList.name
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                    ) {
                        listSavedFiltered.push(tuneList)
                    }
                }

                this.tuneListMSaved = listSavedFiltered
                this.tuneListMCreated = listCreatedFiltered  */
        }
    }

    searchBarClearClicked() {
        //if(this.switchSegment == MyLibrarySwtichSegment.tuneTriggers){
        this.triggers = this.triggersOrig
        this.searchTuneIdOffset = null

        this.allTriggersFetched = false

        //} else if(this.switchSegment == MyLibrarySwtichSegment.tuneLists){
        this.tuneListMCreated = this.tuneListMCreatedOrig
        this.tuneListMSaved = this.tuneListMSavedOrig

        this.searchEntered.tuneLists = false
        this.searchEntered.triggers = false

        this.changeDetector.detectChanges()
        //}
    }

    accordionItem1Toggle() {
        const expanded = this.cdkAccordionItem1.expanded

        const element1 = document.getElementById('accordion-header-1')
        const element2 = document.getElementById('accordion-header-2')

        if (expanded) {
            this.cdkAccordionItem1.close()
            this.cdkAccordionItem2.open()

            element1.classList.remove('expanded-accordion-header')
            element1.classList.add('collapsed-accordion-header')

            element2.classList.add('expanded-accordion-header')
            element2.classList.remove('collapsed-accordion-header')
        } else {
            this.cdkAccordionItem1.open()
            this.cdkAccordionItem2.close()

            element1.classList.add('expanded-accordion-header')
            element1.classList.remove('collapsed-accordion-header')

            element2.classList.remove('expanded-accordion-header')
            element2.classList.add('collapsed-accordion-header')
        }

        this.changeDetector.detectChanges()

        //this.cdkAccordionItem2.toggle()
    }

    accordionItem2Toggle() {
        const expanded = this.cdkAccordionItem2.expanded

        const element1 = document.getElementById('accordion-header-1')
        const element2 = document.getElementById('accordion-header-2')

        if (expanded) {
            this.cdkAccordionItem1.open()
            this.cdkAccordionItem2.close()

            element1.classList.remove('collapsed-accordion-header')
            element1.classList.add('expanded-accordion-header')

            element2.classList.remove('expanded-accordion-header')
            element2.classList.add('collapsed-accordion-header')
        } else {
            this.cdkAccordionItem1.close()
            this.cdkAccordionItem2.open()

            element1.classList.add('collapsed-accordion-header')
            element1.classList.remove('expanded-accordion-header')

            element2.classList.remove('collapsed-accordion-header')
            element2.classList.add('expanded-accordion-header')
        }

        this.changeDetector.detectChanges()

        //this.cdkAccordionItem2.toggle()
    }

    endOfScrollUserTunes(event) {
        if (this.searchEntered.triggers) {
            this.searchBarSearchEntered(this.currentSearchText)
        } else {
            this.getTriggers(event)
        }
    }

    getTriggers(event?) {
        const tic = new Date()
        //console.log(`offset ${this.triggersFetchOffset}`)
        this.mServer
            .getUsersTriggers(this.triggersFetchOffset, this.triggersFetchLimit)
            .subscribe(
                (triggersData) => {
                    console.log(
                        `ticToc: ${new Date().getTime() - tic.getTime()}`
                    )
                    const triggers = triggersData.triggers
                    this.triggers = this.triggers.concat(triggers)
                    this.triggersOrig = this.triggersOrig.concat(triggers)

                    this.triggersLoaded = true

                    this.triggersFetchOffset += triggers.length

                    this.allTriggersFetched = triggersData.allTriggersFetched

                    this.changeDetector.detectChanges()
                },
                (e) => {
                    console.error("Error getting user's tunes", e)
                    this.eh.logSentryError(e)
                    this.triggersLoaded = true
                },
                () => {
                    //this.changeDetector.detectChanges()
                    if (event) {
                        event.target.complete()
                    }
                }
            )
    }

    optionTuneListMClick(event, tuneListLean) {
        event.stopPropagation()
        this.tuneList = this.tuneListDefault
        this.tuneList.listLean = tuneListLean
        this.ionPop.present(event)
    }

    reorderTuneListSclicked() {
        if (this.reorderTuneListActive) {
            const tuneIdArrayToSend = []
            for (const [
                index,
                tuneReorder,
            ] of this.tuneList.listRes.tunesReorder.entries()) {
                tuneIdArrayToSend.push(tuneReorder.tune._id)
            }

            if (this.tuneListOrderChanged) {
                this.mServer
                    .requestTuneListReorder(
                        this.tuneList.listLean._id,
                        tuneIdArrayToSend
                    )
                    .subscribe(
                        (res) => {
                            if (res.status == 200) {
                                this.gs.showToast({
                                    msg: 'Tune list reordered',
                                })
                            } else {
                                this.gs.showToast({
                                    msg: 'Error reordering list, order reset',
                                    type: ToastEnum.error,
                                })
                            }
                        },
                        (e) => {
                            console.error('Error reordering tune list', e)
                            this.gs.showToast({
                                msg: e?.error,
                                type: ToastEnum.error,
                            })
                        }
                    )
            } else {
                this.gs.showToast({ msg: 'No reordering done' })
            }
        } else {
            this.tuneListBeforeReorder = _.cloneDeep(
                this.tuneList.listRes.tunesReorder
            )
        }

        this.reorderTuneListActive = !this.reorderTuneListActive

        //this.changeDetector.detectChanges()
    }

    reorderTuneListSClickedCancel() {
        this.reorderTuneListActive = false
        this.tuneList.listRes.tunesReorder = this.tuneListBeforeReorder
        this.tuneListOrderChanged = false
    }

    reorderEvent(event) {
        const from = event.detail.from
        const to = event.detail.to

        if (from != to) {
            this.tuneListOrderChanged = true
        }

        console.log('Dragged from index', event.from, 'to', event.to)

        const tmpTune: ITuneReorder = this.tuneList.listRes.tunesReorder[from]

        // Remove the element from the original position
        this.tuneList.listRes.tunesReorder.splice(from, 1)

        // Insert the element at the new position
        this.tuneList.listRes.tunesReorder.splice(to, 0, tmpTune)

        event.detail.complete(false)
    }

    shuffleClicked() {
        this.tuneListPlayer.setShuffle(!this.shuffleActive)
    }
}
