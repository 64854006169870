import { Tune } from './../../classes-enums-interfaces-types/classes/classes'
import { HelperFunctionsService } from './../helper-functions/helper-functions.service'
import { startWith } from 'rxjs/operators'
import { Platform, ModalController } from '@ionic/angular'
import { AppStateService } from './../app-state.service'
import { AppComponent } from './../../app.component'
import {
    NativeVolumeControl,
    NativeVolumeControlWeb,
    NativeVolumeControlPlugin,
    MPlayerState,
} from 'native-volume-control-npm-package-name'
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import {
    Injectable,
    Injector,
    Inject,
    RendererFactory2,
    Renderer2,
} from '@angular/core'
import { registerPlugin, Capacitor } from '@capacitor/core'
import { mGlobal } from '../../mglobal'
import { CookieService } from 'ngx-cookie-service'
import { DOCUMENT } from '@angular/common'
import { BehaviorSubject, timer } from 'rxjs'
import { ENV } from '../../../../environments/environment'
import { Http, HttpResponse } from '@capacitor-community/http'
import { GlobalService } from '../global.service'
import {
    DevicePlatformE,
    ToastEnum,
} from '../../classes-enums-interfaces-types/enums/enums.enum'
import { Err } from 'neverthrow'
import type { ISpotDevice } from '../../../../../shared/dist/shared.interface'
import { ErrorHandlerService } from '../error-handler/error-handler.service'
import { FullscreenSpinnerComponent } from '../../components/modals/fullscreen-spinner/fullscreen-spinner.component'
import { ModalsE } from '../../classes-enums-interfaces-types/enums/enums.enum'
import * as Sentry from '@sentry/angular-ivy'
import { UserStoredSettings } from '../../classes-enums-interfaces-types/interfaces/interfaces'

@Injectable({
    providedIn: 'root',
})
export class PlayerService {
    loopTimes = 0 // 1 is the first loop
    origVolume: number
    spotAppRemoteInitiated: boolean = false // native
    shouldFade: boolean = true

    renderer: Renderer2
    tuneId = '' // id of currently playing tune
    NO_TUNE_ID = 'CLEARED_TUNE_ID'
    tuneIdPrev = ''
    deviceId
    fP = mGlobal.fadeParams
    volumeDeltaFI: number
    volumeDeltaFIPrev: number
    volumeDeltaFO: number

    isPlaying: boolean = false
    currentTrackId: string | null
    prevTrackId: string | null

    fadeOutTimerSub
    fadeInTimerSub
    isFading: boolean = false

    webSdkInjected: boolean = false
    private webSdkPlayerNeedConstruction: boolean = true

    isLooping: boolean = false

    capPlatform: string

    private spotAccessToken: string
    private spotAccessTokenExpires: Date

    echo = registerPlugin('Echo')
    prevOrigVol: number | null

    /// for progressbar
    loopTimeInterval = null
    loopTimeSteps: number = 0
    loopTime$: BehaviorSubject<number> = new BehaviorSubject(0)
    loopTimesLimitReached$: BehaviorSubject<boolean> = new BehaviorSubject(
        false
    )
    ///

    loopItr$: BehaviorSubject<number> = new BehaviorSubject(null)

    loopPending: boolean = false
    // could reused loopPending, for readability
    loopPendingSeekRelative: boolean = false
    startTimeOffset: number = 0

    tune
    loopLimit = 3

    selectedTune$ = new BehaviorSubject<Tune | null>(null)
    shouldLog = true

    //playCalledAteastOnce = tgrue
    private playCalledAtleastOnce = false
    private pendingTrackIdAfterSilentTrack = null
    private pedningStartTimeAfterSilentTrack = null
    playerStateChangeIdentictalEvents: number // this is offset by one naturally, 2 identical events makes this 1

    playerStateEventPrev: string // reset between loops aka null on first loop
    prevTuneId: string // kept between loops aka defined on first loop
    // needed to deal with tune endtime offset on first loop as spot will send correct number of events if last tune was same
    // can also be due to .resume is used in that case

    experimentalLooping: boolean = true

    constructor(
        @Inject(DOCUMENT) private _document,
        private rendererFactory: RendererFactory2,
        private cookieService: CookieService,
        private http: HttpClient,
        private appStateService: AppStateService,
        private platform: Platform,
        private gs: GlobalService,
        private hf: HelperFunctionsService,
        private modalCtrl: ModalController,

        private injector: Injector
    ) {
        this.renderer = rendererFactory.createRenderer(null, null)
        this.serviceInit()
    }

    // should be null but cant be as a client side generated tune has null as _id

    isPlayerReady$: BehaviorSubject<boolean> = new BehaviorSubject(false)
    isPlayerReady: boolean

    playerStateChanged$ = new BehaviorSubject<MPlayerState>({
        artistName: '',
        isPlaying: false,
        currentTrackId: '',
        playbackPosition: 0,
        trackName: '',
        isLoading: true,
    }) //#OPT get currentPlayState

    isLooping$: BehaviorSubject<boolean> = new BehaviorSubject(false)
    fadeInTimer
    fadeOutTimer
    playerStateItr = 0
    eh: ErrorHandlerService
    fadeIn = false

    currentTracksDuration: number

    nvcWeb: NativeVolumeControlWeb = new NativeVolumeControlWeb()
    fullscreenSpinnerModal: HTMLIonModalElement = null

    serviceInit() {
        this.capPlatform = this.gs.getCapPlatform()

        this.shouldFade =
            this.gs.getDevicePlatform() == DevicePlatformE.android ||
            !ENV.PRODUCTION
                ? true
                : false
        //this.shouldFade = false
        this.hf.consoleLog(`shouldFade: ${this.shouldFade}`, this.shouldLog)
        this.hf.consoleLog(`initPlayerService`, this.shouldLog)
        this.initAsync()

        this.appStateService.appStateActive$.subscribe((isActive) => {
            // !isActive = goes to background
            if (!isActive) {
                Sentry.addBreadcrumb({
                    category: 'pauseUndefined',
                    message: 'goeToBackGround',
                    level: 'info',
                })

                this.pauseTrack()
            }
        })

        this.platform.ready().then(() => {
            console.log('platform rdy')
            this.platform.backButton.subscribeWithPriority(
                80,
                (processNextHandler) => {
                    console.log('backButtonHandler called')
                    Sentry.addBreadcrumb({
                        category: 'pauseUndefined',
                        message: 'backbutton80',
                        level: 'info',
                    })
                    //this.pauseTrack()
                    processNextHandler()
                }
            )
        })

        this.gs.userSettings$.subscribe((settings: UserStoredSettings) => {
            if (settings) {
                this.experimentalLooping = settings.experimentalLooping
            }
        })
    }

    checkIdenticalPlayerState() {}

    async initAsync() {
        await this.nvcWeb.addListener(
            'isPlayerReady',
            (isPlayerReady: boolean) => {
                this.isPlayerReady = isPlayerReady

                if (this.fullscreenSpinnerModal && isPlayerReady) {
                    this.fullscreenSpinnerModal.dismiss()
                    this.fullscreenSpinnerModal = null
                }

                this.isPlayerReady$.next(isPlayerReady)
            }
        )

        const stateListener = await this.nvcWeb.addListener(
            'playerStateChangeEvent',
            (playerState: MPlayerState) => {
                if (
                    playerState &&
                    this.playerStateEventPrev == JSON.stringify(playerState)
                    //this.hf.compareJsonObjectsRemoveFields(this.playerStateEventPrev, playerState,["timestamp"])
                    /// && playerState.trackName != 'Silent Track'
                ) {
                    this.playerStateChangeIdentictalEvents++
                    console.log(`idd ${this.playerStateChangeIdentictalEvents}`)
                } else {
                    this.playerStateChangeIdentictalEvents = 0
                }

                //console.log(`not double play ${JSON.stringify(playerState)} ${this.playerStateChangeIdentictalEvents}`)

                this.playerStateEventPrev = JSON.stringify(playerState)

                this.hf.consoleLog('db1 4', this.shouldLog)
                this.hf.consoleLog(
                    `state: ${this.playerStateItr} ${playerState.currentTrackId} ${playerState.isPlaying} ${playerState.playbackPosition}`,
                    this.shouldLog
                )

                this.playerStateItr++
                this.isPlaying = playerState.isPlaying

                this.playerStateChanged$.next(playerState)

                this.prevTrackId = this.currentTrackId

                //if song has finished, and auto play is option for user so need to pause song

                this.hf.consoleLog('d0', this.shouldLog)
                if (
                    !this.playCalledAtleastOnce &&
                    playerState.isPlaying &&
                    //playerState.playbackPosition > 5 && ///
                    // maybe a bit too optimze when 10ms is enougth delay, remove if causing propblems
                    this.playerStateChangeIdentictalEvents >= 1 &&
                    playerState.isLoading == false &&
                    this.currentTrackId == this.gs.silentTrackId
                ) {
                    console.log('played atleast once true')
                    this.hf.consoleLog('d1', this.shouldLog)
                    this.playCalledAtleastOnce = true

                    if (this.pedningStartTimeAfterSilentTrack != null) {
                        this.hf.consoleLog('d2', this.shouldLog)
                        this.playTrackIdTime(
                            this.pendingTrackIdAfterSilentTrack,
                            this.pedningStartTimeAfterSilentTrack,
                            true
                        )
                        this.pedningStartTimeAfterSilentTrack = null
                        this.pendingTrackIdAfterSilentTrack = null
                    } else {
                        this.hf.consoleLog('d3', this.shouldLog)
                        this.playTrackId(this.pendingTrackIdAfterSilentTrack)
                        this.pendingTrackIdAfterSilentTrack = null
                    }
                } else {
                    if (
                        this.prevTrackId &&
                        this.prevTrackId != this.currentTrackId
                    ) {
                        Sentry.addBreadcrumb({
                            category: 'pauseUndefined',
                            message: 'hardBack',
                            level: 'info',
                        })

                        // #debug most likely the problem
                        console.log('atleast paused')
                        this.pauseTrack()
                    } else if (this.isPlaying) {
                        this.currentTrackId = playerState.currentTrackId
                    }

                    if (this.loopPending) {
                        this.hf.consoleLog(playerState, this.shouldLog)
                        this.hf.consoleLog(this.currentTrackId, this.shouldLog)
                        this.hf.consoleLog(
                            this.tune.slimTuneTrack.trackId,
                            this.shouldLog
                        )
                    }

                    if (
                        // trackId is market dependent and no way to getLinked from from playerState.
                        //#TODO update db with current ids, e.g. available_markets.length > 0
                        //# opt undefined === undefined => true

                        (this.loopPending || this.loopPendingSeekRelative) &&
                        playerState.isPlaying &&
                        (playerState.currentTrackId ==
                            this.tune.slimTuneTrack.trackId ||
                            (playerState.artistName ==
                                this.tune.slimTuneTrack.artistName &&
                                playerState.trackName?.toLowerCase() ==
                                    this.tune.slimTuneTrack.trackName?.toLowerCase()))
                    ) {
                        if (
                            !this.experimentalLooping ||
                            (this.tuneId == this.tuneIdPrev &&
                                this.playerStateChangeIdentictalEvents == 0) ||
                            (this.loopTimes == 0 &&
                                this.playerStateChangeIdentictalEvents >= 2) || // 4 instead of 2 does not always happen
                            (this.loopTimes > 0 &&
                                this.playerStateChangeIdentictalEvents >= 1) //
                        ) {
                            console.log('not double play enter')
                            const startTimeOffset = this.loopPendingSeekRelative
                                ? Math.max(
                                      playerState.playbackPosition -
                                          this.tune.startTime,
                                      0
                                  )
                                : 0
                            this.hf.consoleLog(startTimeOffset, this.shouldLog)

                            // here instead of in loop, as the event could theoretically be called again before the async loopTune is called
                            this.loopPending = false
                            this.loopPendingSeekRelative = false
                            this.isLooping = true
                            this.isLooping$.next(true)
                            this.loopTune(this.tune, startTimeOffset)
                        }
                    }
                }
            }
        )

        this.gs.userSettings$.subscribe((settings: UserStoredSettings) => {
            const loopLimit = settings?.loopLimit
            if (
                loopLimit &&
                loopLimit >= mGlobal.loopLimit.min &&
                loopLimit <= mGlobal.loopLimit.max
            ) {
                this.loopLimit = loopLimit
            }
        })
    }

    ngOnDestroy() {
        this.platform.backButton.unsubscribe()
    }

    disconnectPlayer() {
        if (this.capPlatform == 'android') {
            NativeVolumeControl.disconnectPlayerAndRemoveListerns()
        } else {
            this.nvcWeb.disconnectPlayerAndRemoveListerns()
        }
    }

    resetSpotifyPlayerVariables() {
        this.loopTimes = 0
        this.tuneId = '' // id of currently playing Tune
        this.tuneIdPrev = ''
        this.isPlaying = false
        this.isLooping = false
        this.currentTrackId = null
        this.prevTrackId = null
        this.loopPending = false
        this.tune = null
        // until here prop doesnt matter, should be done in prev pauase

        this.deviceId = null
        this.playCalledAtleastOnce = false
    }

    setWebSdkInjected() {}

    async initSpotifyPlayer(playerName?: string) {
        // t is not a function. (In 't(e.eventData)', 't' is undefined)

        if (this.capPlatform != 'android' && !this.webSdkInjected) {
            if (!this.webSdkInjected) {
                this.injectSpotifyWebSdk(playerName)
            } else {
                this.constructSpotWebPlayer()
            }
            console.log('spotifyInit')
        } else if (
            this.gs.isNativeCapPlatform() &&
            !this.spotAppRemoteInitiated
        ) {
            this.spotAppRemoteInitiated = true
            await NativeVolumeControl.initNative({
                successCallback: new Function(),
                failureCallback: new Function(),
                accessToken: this.spotAccessToken,
            })
            if (ENV.DEBUG) {
                await new Promise((r) => setTimeout(r, 2000))
            }
        }
    }

    injectSpotifyWebSdk(playerName?) {
        //ACCOUNTS
        //api
        //apresolve
        //audio-fa
        //cpapi
        //seektables
        //spclient
        //gew4-dealer

        this.playCalledAtleastOnce = false
        console.log('injecting spotify webSdk')
        const s = this.renderer.createElement('script')
        s.type = 'text/javascript'
        s.src = 'https://sdk.scdn.co/spotify-player.js'
        s.text = ``
        this.renderer.appendChild(this._document.body, s)
        //@ts-ignore
        window.onSpotifyWebPlaybackSDKReady = () => {
            console.log('onSpotifyWebPlaybackSDKReady')
            this.webSdkInjected = true
            this.constructSpotWebPlayer(playerName)

            //this.nvcWeb.initPlayerWeb(player)
        }
        //this.elementRef.nativeElement.appendChild(s);;
    }

    setWebSdkPlayerNeedConstruction(value: boolean) {
        console.log(`webPlayerConstructed set to: ${value}`)
        this.webSdkPlayerNeedConstruction = value
    }

    async constructSpotWebPlayer(playerName?) {
        // Make sure valid spotToken before calling this method
        if (this.webSdkPlayerNeedConstruction) {
            // maybe b bit hacky, unclear if moving it from end to here causes side effects
            // the player is initioned on web sdk ready and on route change but neither has finished when the other is called
            this.webSdkPlayerNeedConstruction = false
            // constructSpotWebPlayer can be called multiple times from somewhere double it shouldn't show multiple spinners
            if (!this.fullscreenSpinnerModal) {
                this.fullscreenSpinnerModal = await this.modalCtrl.create({
                    component: FullscreenSpinnerComponent,
                    id: ModalsE.fullScreenSpinner,
                    cssClass: 'ion-modal-fullscreen-spinner',
                    showBackdrop: true,
                    backdropDismiss: false,
                })

                await this.fullscreenSpinnerModal.present()

                setTimeout(() => {
                    if (this.fullscreenSpinnerModal) {
                        this.fullscreenSpinnerModal.dismiss()
                        this.fullscreenSpinnerModal = null
                        this.gs.showToast({
                            msg: 'Failed to initialize Spotify player',
                            header: 'Error',
                            duration: 4000,
                            type: ToastEnum.error,
                        })
                    }
                }, 4000)
            }

            this.resetSpotifyPlayerVariables()

            if (await this.nvcWeb.getPlayer()) {
                await this.nvcWeb.disconnectPlayerAndRemoveListerns()
            }

            await this.nvcWeb.setSpotAccessToken({
                spotAccessToken: this.getSpotAccessToken(),
            })
            this.webSdkPlayerNeedConstruction = false
            this.nvcWeb.initPlayerWeb(playerName)
        } else {
            console.log('webPlayerSdk does not need construction')
        }
    }

    async getNvcDeviceId() {
        //return 'okDeviceId'
        return (await this.nvcWeb.getDeviceId()).deviceId
    }

    async pauseTrack() {
        // called on router change and will error otherwise
        console.log('paused')
        if (!this.webSdkPlayerNeedConstruction) await this.nvcWeb.pauseTrack()

        this.resetPlayer()
    }

    async resetPlayer() {
        //this.currentTrackId = null
        //this.prevTrackId = null

        this.tuneIdPrev = ''
        this.resetLoop()
    }

    async resetLoop() {
        this.hf.consoleLog('resetLoop', this.shouldLog)
        this.hf.consoleLog(this.isLooping, this.shouldLog)
        if (this.isLooping) {
            this.clearAllTimers()
            this.fadeIn = false
            this.prevOrigVol = null
            this.loopTimes = 0
            this.loopTimeSteps = 0
            this.loopTime$.next(0)
            this.loopPending = false
            this.loopPendingSeekRelative = false

            this.tune = null
            this.isLooping = false

            if (this.capPlatform == 'android') {
                //web doesnt know current android vol
                NativeVolumeControl.resetLoop()
            } else if (this.isFading) {
                this.setVolume(this.origVolume)
            }
            this.isFading = false
            this.isLooping$.next(false)
            this.loopItr$.next(0)
        }
    }

    async seekRelResetLoop() {
        console.log('seekResetLoop')
        console.log(this.isLooping)

        if (this.isLooping) {
            this.clearAllTimers()
            this.isFading = false
            this.loopPendingSeekRelative = false
        }
    }

    //tune is used when play is done togther with loop e.g in edit tune, plays frm beginning
    async playTrackId(trackId: string) {
        try {
            console.log('playTrack')

            if (this.isLooping) {
                this.resetLoop()
            }

            if (!this.playCalledAtleastOnce) {
                this.pendingTrackIdAfterSilentTrack = trackId
                this.pedningStartTimeAfterSilentTrack = null
                //#opt do in nvc
                await this.nvcWeb.activatePlayerElement()
                await new Promise((resolve) => setTimeout(resolve, 100))
                await this.nvcWeb.playTrackId({
                    trackId: this.gs.silentTrackId,
                })
                await new Promise((resolve) => setTimeout(resolve, 100))
                await this.nvcWeb.resumeTrack()
            }
            //await this.nvcWeb.resumeTrack()
            //return
            else {
                if (trackId && trackId == this.currentTrackId) {
                    //null == null -> true
                    await this.nvcWeb.resumeTrack()
                } else {
                    this.prevTrackId = this.currentTrackId
                    this.currentTrackId = trackId
                    const isPlaying = await this.nvcWeb.playTrackIdTime({
                        trackId: trackId,
                        time: 0,
                    })
                }
            }
        } catch (e) {
            if (e?.status == 502) {
                this.gs.showToast({
                    msg: 'Error from the Spotify player, retry pressing play in 2 seconds',
                    duration: 4000,
                    type: ToastEnum.error,
                })
            }
            throw e
        }
    }

    setPlayCalledAteastOnce(value: boolean) {
        this.hf.consoleLog(
            `setPlayCalledAteastOnce to ${value}`,
            this.shouldLog
        )
        this.playCalledAtleastOnce = value
    }

    async playTrackIdTime(trackId, time, shouldDelay?: boolean) {
        try {
            if (this.isLooping) {
                this.resetLoop()
            }

            if (!this.playCalledAtleastOnce) {
                //#opt do in nvc
                // Done for player to boot up, or else first loop is way delayed
                this.pendingTrackIdAfterSilentTrack = trackId
                this.pedningStartTimeAfterSilentTrack = time
                await this.nvcWeb.activatePlayerElement()

                await this.nvcWeb.playTrackIdTime({
                    trackId: this.gs.silentTrackId,
                    time: 5,
                })
                await this.nvcWeb.resumeTrack()
            } else {
                if (trackId == this.currentTrackId && trackId) {
                    if (this.isPlaying) {
                        await this.nvcWeb.seekTrackAbsolute({
                            absoluteTime: time,
                        })
                    } else {
                        await this.nvcWeb.seekTrackAbsolute({
                            absoluteTime: time,
                        })
                        await this.nvcWeb.resumeTrack()
                    }
                } else {
                    this.currentTrackId = trackId

                    // this pause is needed here to to get
                    // popping sound on chrome android (seem fine in ff, on SG S8)
                    // when switching track
                    await this.nvcWeb.pauseTrack()
                    // #debug below await don't do anything.
                    // Just sanity check that this await cant delay playerStateChangeEvent
                    // observer callback

                    // used to prevent 502 of first loop, waiting for 2 identical events is not enough
                    // idd 1 and 2s0 ms delay is not enough for pc gives 502
                    if (shouldDelay) {
                        await new Promise((resolve) => setTimeout(resolve, 30))
                    }
                    await this.nvcWeb.playTrackIdTime({
                        trackId: trackId,
                        time: time,
                    })
                    console.log('not double Awaited')
                }
            }
        } catch (e) {
            if (e?.response.status == 502) {
                this.gs.showToast({
                    msg: 'Error from the Spotify player, retry pressing play in 2 seconds',
                    duration: 5000,
                    type: ToastEnum.error,
                })
            }

            throw e
        }
    }

    //#fix will bug, aka shift song if going outside duration
    async seekTrackRelative(relativeTime) {
        console.log('seekTrackRelative')
        this.hf.consoleLog('db1 0', this.shouldLog)
        if (this.isLooping) {
            this.hf.consoleLog('db1 1', this.shouldLog)
            const date = new Date()

            if (relativeTime > 0) {
                console.log('db1 2')
                const marginMs = 500
                const loopProgressTime =
                    (await this.nvcWeb.getPlaybackTime()).playbackTime -
                    this.tune.startTime
                // relativeTime is so far only 5000 and can't land inside fade in when positive
                const fadeOutDuration: number = this.shouldFade
                    ? this.fP.fadeOutDuration
                    : 0

                if (
                    loopProgressTime + relativeTime <
                    this.tune.stopTime -
                        this.tune.startTime -
                        fadeOutDuration -
                        marginMs
                ) {
                    this.hf.consoleLog('db1 3', this.shouldLog)
                    this.seekRelResetLoop()

                    this.loopPendingSeekRelative = true

                    this.hf.consoleLog(
                        'db1 loopPendingSeek true',
                        this.shouldLog
                    )

                    this.nvcWeb.seekTrackRelative({
                        relativeTime: relativeTime,
                    })

                    // for better timing
                }
                // restart loop if seek lands outside loopDuration
                else {
                    this.loopPending = true
                    this.nvcWeb.seekTrackAbsolute({
                        absoluteTime: this.tune.startTime,
                    })
                }
            } else if (relativeTime < 0) {
                // tictoc is 5ms
                const loopProgressTime =
                    (await this.nvcWeb.getPlaybackTime()).playbackTime -
                    this.tune.startTime

                const fadeInDuration: number = this.shouldFade
                    ? this.fP.fadeInDuration
                    : 0

                if (loopProgressTime - relativeTime - fadeInDuration > 0) {
                    this.seekRelResetLoop()

                    this.loopPendingSeekRelative = true

                    this.nvcWeb.seekTrackRelative({
                        relativeTime: relativeTime,
                    })
                } else {
                    this.loopPending = true
                    this.nvcWeb.seekTrackAbsolute({
                        absoluteTime: this.tune.startTime,
                    })
                }
            }
        } else {
            await this.nvcWeb.seekTrackRelative({
                relativeTime: relativeTime,
            })
        }
    }

    // decap prob. for native use
    webApiPlay() {
        console.log('Got to webPlayApi', this.shouldLog)
        const params = new HttpParams().set('device_id', this.deviceId)

        const body = {
            uris: ['spotify:track:3pXtviby8sWKzZoilboCZG'],
        }
        return this.http.put(
            'https://api.spotify.com/v1/me/player/play',
            body,
            {
                headers: new HttpHeaders().set(
                    'Authorization',
                    'Bearer ' + this.getSpotAccessToken()
                ),
                params,
            }
        )
    }

    // decap prob. for native use
    webApiTransferPlayback(deviceId?: string) {
        if (!deviceId) {
            deviceId = this.deviceId
        }
        //deviceId = 'b33804f45f753faf63cf34d5ce17a35513cd4c3f'
        console.log('Got to webPlayApi', this.shouldLog)
        const body = {
            device_ids: [deviceId],
            play: true,
        }
        return this.http.put('https://api.spotify.com/v1/me/player', body, {
            headers: new HttpHeaders().set(
                'Authorization',
                'Bearer ' + this.getSpotAccessToken()
            ),
        })
    }

    //#fix will bug, aka shift song if going outside duration
    seekTrackAbsolute(absoluteTime) {
        this.resetLoop()
        console.log('seekTrackAbsolute')
        this.nvcWeb.seekTrackAbsolute({
            absoluteTime: absoluteTime,
        })
    }

    async loopTuneRequest(tune: any): Promise<string | void> {
        try {
            if (tune.startTime === null)
                return 'The Tune needs to have a start time'

            if (tune.stopTime === null)
                return 'The Tune needs to have a stop time'

            this.loopTimes = 0
            if (
                tune.stopTime - tune.startTime >
                this.fP.shortestTotalTuneLength
            ) {
                this.tuneId = tune._id
                if (this.tuneId == this.tuneIdPrev && this.tuneId) {
                    this.hf.consoleLog('loopTuneIonic Pause', this.shouldLog)
                    this.pauseTrack()
                } else {
                    this.hf.consoleLog('loopTuneIonic Loop', this.shouldLog)
                    this.tuneIdPrev = this.tuneId

                    // if(this.capPlatform === 'android' ){
                    //     await NativeVolumeControl.loopTune({
                    //         trackId:tune.slimTuneTrack.trackId,
                    //         startTime:tune.startTime,
                    //         stopTime:tune.stopTime
                    //     })
                    // } else {
                    await this.prepLoopTuneAndPlay(tune)
                    //}
                    //return
                }
            } else {
                //fix ugly ui glich will follow, ps should emit its state
                const err = `The Tune needs to be longer than ${
                    this.fP.shortestTotalTuneLength / 1000
                }s`
                console.log(err)
                return err
            }
        } catch (e) {
            console.error('loopTuneRequest error', e)
            this.resetPlayer()
            throw e
        }
    }

    async getPlaybackTime() {
        try {
            const playBackTime = (await this.nvcWeb.getPlaybackTime())
                .playbackTime
            return playBackTime
        } catch (e) {
            console.error(`Error getting playbacktime`, e)
            throw e
        }
    }

    /*async setSpotAccessTokenFromCookie() {

        const spotAccessToken = (<any>this.cookieService.getObject('spotAccessToken'))
        const spotAccessTokenExpiresIn = (<any>this.cookieService.getObject('spotAccessTokenExpiresIn'))
        this.cookieService.remove('spotAccessToken')
        this.cookieService.remove('spotAccessTokenExpiresIn')

        if (this.gs.isNativeCapPlatform()) {
            try {
                await NativeVolumeControl.setSpotAccessToken(spotAccessToken)
            } catch (e) {
                //Fix UI
                console.error(`Failed to setNativeSpotAccessToken`)
            }
        } else {
            this.spotAccessToken = spotAccessToken
            this.spotAccessTokenExpires = new Date(spotAccessTokenExpires)
        }
    } */

    //#Opt make cookie
    setSpotAccessToken(spotTokens) {
        if (spotTokens) {
            this.spotAccessToken = spotTokens.accessToken
            this.spotAccessTokenExpires = spotTokens.expires //new Date(spotTokens.expires)
        } else {
            this.spotAccessToken = null
            this.spotAccessTokenExpires = null
        }
    }

    getSpotAccessToken() {
        //#opt store in native later
        if (this.gs.isNativeCapPlatform()) {
            //Didnt do capacitor storage because it is async and messes up.
            return this.spotAccessToken
        } else {
            const spotTokenStr = this.cookieService.get('spotTokens')

            if (spotTokenStr) {
                const spotTokens = JSON.parse(spotTokenStr)
                return spotTokens?.accessToken
            }

            return null
        }

        // try{
        //     const spotTokens = await  Http.getCookie({url:ENV.DOMAIN_OF_BACKEND,key:'spotTokens'})
        //     return spotTokens?.accessToken
        // } catch (e) {
        //     console.error('Error getting spotToken cookkie',e)
        //     return null
        // }
    }

    isCurrentSpotTokenValid() {
        //const spotTokens = <any>this.cookieService.getObject('spotTokens')
        const spotTokenStr = this.cookieService.get('spotTokens')

        if (!spotTokenStr) {
            return false
        }

        const spotToken: any = JSON.parse(spotTokenStr)
        if (spotToken && !this.hasCurrentSpotTokenExpired()) {
            return true
        }
        return false
    }

    hasCurrentSpotTokenExpired() {
        const t = new Date() //.getTime()
        let expires
        if (this.gs.isNativeCapPlatform()) {
            expires = new Date(this.spotAccessTokenExpires)
        } else {
            const spotToken: any = JSON.parse(
                this.cookieService.get('spotTokens')
            )
            expires = new Date(spotToken?.expires)
        }
        if (expires > t) {
            //comparision with undefinied always yields false
            return false
        }
        return true
    }

    private async prepLoopTuneAndPlay(tune) {
        this.clearAllTimers()
        // ugly not to have in reset loop
        this.loopTimesLimitReached$.next(false)
        await this.resetLoop()
        this.tune = tune
        this.loopPending = true
        this.selectedTune$.next(tune)

        this.playerStateChangeIdentictalEvents = 0
        this.playerStateEventPrev = null

        await this.playTrackIdTime(tune.slimTuneTrack.trackId, tune.startTime)
    }

    private async
    loopTune(
        tune,
        startTimeOffset: number = 0
        //trackId: string, startTime: number, stopTime: number
    ) {
        //#opt
        this.loopTimes++
        this.loopItr$.next(this.loopTimes)
        this.hf.consoleLog(this.loopTimes, this.shouldLog)

        const loopFullDuration = tune.stopTime - tune.startTime
        //currentTrackId listner
        this.hf.consoleLog(`FadeIn: ${this.fadeIn}`, this.shouldLog)
        this.clearAllTimers()

        // done in parellel for reasons of starting progressBar at correct time and clearing all timers on loopRestart
        let ticToc

        // to counter pause after app close due to iOS spot init app switch
        // not pretty but one line efftive
        this.tuneIdPrev = this.tuneId // not sure if still needed set in request

        this.loopTimeSteps = 0

        // update loop postion GUI aka loop-progress-bar
        this.loopTimeInterval = setInterval(() => {
            const loopPosition = Math.min(
                (startTimeOffset + this.loopTimeSteps * 100) / loopFullDuration,
                1
            )
            // if(this.loopTimeSteps % 10 == 0){
            // 	this.hf.consoleLog(`loopPos ${loopPosition}`)
            // }

            if (loopPosition >= 1) {
                //this.loopTimeSteps = 0
                this.hf.consoleLog('local clear', this.shouldLog)
                //clearInterval(this.loopTimeInterval)
                //this.loopTime$.next(-1)
            } else {
                this.loopTime$.next(loopPosition)
                this.loopTimeSteps++
            }

            //console.log(loopPosition)
        }, 100)

        this.hf.consoleLog('loopTimeInterval', this.shouldLog)
        this.hf.consoleLog(this.loopTimeInterval, this.shouldLog)

        if (this.shouldFade) {
            //catches volume change during loop, and updates for next loop

            const tic = new Date().getTime()

            //pressied in fade

            this.hf.consoleLog(`origVolume: ${this.origVolume}`)
            if (this.origVolume == -1) {
                console.error('Error getting volume from NVC')
                return
            } else {
                if (this.loopTimes > 1) {
                    this.volumeDeltaFIPrev = this.volumeDeltaFI
                    this.fadeInTimer = timer(0, this.fP.fISP)
                    this.fadeInTimerSub = this.fadeInTimer.subscribe(
                        this.fadeInTimerFunc
                    )
                }
            }

            /*if(this.prevOrigVol && (this.prevOrigVol != this.origVolume)){
					this.origVolume = this.prevOrigVol
				} */

            ticToc = new Date().getTime() - tic
            //#Opt listen for play start subscription before proceed

            this.fadeOutTimer = timer(
                tune.stopTime -
                    tune.startTime -
                    this.fP.fadeOutDuration -
                    ticToc -
                    startTimeOffset,
                this.fP.fOSP
            )
        } else {
            this.fadeOutTimer = timer(
                tune.stopTime - tune.startTime - startTimeOffset
            )
        }

        this.playerStateChangeIdentictalEvents = 0
        this.playerStateEventPrev = null

        this.fadeOutTimerSub = this.fadeOutTimer.subscribe(
            this.fadeOutTimerFunc
        )

        // clearTimer
        // creatTimer with delay
        // each timer tick dec volume
        // when timer done call reqursive
    }

    private fadeInTimerFunc = async (
        stepNbr: number,
        volumeDeltaFI: number
    ) => {
        const fadeInSetVolume =
            this.origVolume * this.fP.lowestVolumePercentOfOrig +
            this.volumeDeltaFIPrev * (stepNbr + 1)
        //this.hf.consoleLog(`fIT, step: ${stepNbr} ${fadeInSetVolume}`)

        if (stepNbr < this.fP.fINoS) {
            this.setVolume(fadeInSetVolume)
        }
        if (stepNbr >= this.fP.fINoS) {
            //this.loopTune(trackId, startTime, stopTime)
            this.isFading = false
            this.fadeInTimerSub.unsubscribe()
        }
    }

    private fadeOutTimerFunc = async (stepNbr: number) => {
        if (this.shouldFade) {
            if (stepNbr == 0) {
                this.prevOrigVol = this.origVolume
                // fine only takes 1ms to fetch volume
                this.origVolume = await this.getVolume()

                this.volumeDeltaFO =
                    (this.origVolume -
                        this.origVolume * this.fP.lowestVolumePercentOfOrig) /
                    this.fP.fONoS
                this.volumeDeltaFI =
                    (this.origVolume -
                        this.origVolume * this.fP.lowestVolumePercentOfOrig) /
                    this.fP.fINoS
            }
            const fadeOutSetVolume =
                this.origVolume - (stepNbr + 1) * this.volumeDeltaFO
            //this.hf.consoleLog(`fOT, step: ${stepNbr} ${fadeOutSetVolume}`)
            this.isFading = true
            if (stepNbr < this.fP.fONoS) {
                //console.log('fOT, step: ', stepNbr, fadeOutSetVolume)
                this.setVolume(fadeOutSetVolume)
            } else {
                if (this.loopTimes < this.loopLimit) {
                    this.fadeOutTimerSub.unsubscribe()
                    // will likely take care of buffering and resume by itself
                    this.hf.consoleLog('Px, seek', this.shouldLog)
                    //# opt do fade in after seeked has happend
                    this.loopPending = true
                    this.nvcWeb.seekTrackAbsolute({
                        absoluteTime: this.tune.startTime,
                    })

                    //# OPT w8 for seek complete
                    // Compares with fade in or not
                    /*if(this.fadeIn){
						this.fadeInTimerSub = this.fadeInTimer.subscribe(fadeInTimerFunc)
						this.fadeIn = !this.fadeIn
					} else {
						await NativeVolumeControl.setVolume({volume:this.origVolume})
						this.fadeIn = !this.fadeIn
						this.loopTune(trackId, startTime, stopTime)
					} */
                } else {
                    this.pauseTrack()
                    this.resetLoop()
                    this.loopTimesLimitReached$.next(true)
                    // this.loopTimes=0
                    // await this.nvcWeb.setVolume({volume:this.origVolume})
                }
            }
        } else {
            if (this.loopTimes < this.loopLimit) {
                this.fadeOutTimerSub.unsubscribe()
                // will likely take care of buffering and resume by itself
                this.hf.consoleLog('Px, seek no fade', this.shouldLog)
                this.loopPending = true

                this.playerStateChangeIdentictalEvents = 0

                this.nvcWeb.seekTrackAbsolute({
                    absoluteTime: this.tune.startTime,
                })
                //# OPT w8 for seek complete
                //this.loopTune(trackId, startTime, stopTime)
            } else {
                this.loopTimes = 0
                this.pauseTrack()
                this.loopTimesLimitReached$.next(true)
            }
        }
    }

    async getVolume(): Promise<number> {
        let volume
        if (this.capPlatform == 'web') {
            volume = (await this.nvcWeb.getVolume()).volume
        } else {
            volume = (await NativeVolumeControl.getVolume()).volume
        }
        return volume
    }

    async setVolume(volume: number) {
        if (typeof volume === 'number' && !isNaN(volume)) {
            if (this.capPlatform == 'web') {
                await this.nvcWeb.setVolume({ volume: volume })
            } else {
                await { volume: volume }
            }
        }
    }

    clearAllTimers() {
        if (this.fadeOutTimerSub) this.fadeOutTimerSub.unsubscribe()
        if (this.fadeInTimerSub) this.fadeInTimerSub.unsubscribe()
        this.hf.consoleLog(this.loopTimeInterval, this.shouldLog)
        if (this.loopTimeInterval) {
            clearInterval(this.loopTimeInterval)
            this.loopTimeInterval = null
        }
    }

    // All interaction with NVC should go through player as it is global, to avoid parallell bugs
    async getSpotDevices(): Promise<ISpotDevice[]> {
        let spotDevices = (await this.nvcWeb.getAvailableDevices()).spotDevices
        return spotDevices
    }

    async getNvcSpotAccessToken() {
        const spotAccessToken = await this.nvcWeb.getSpotAccessToken()
        return spotAccessToken
    }

    async setNvcSpotAccessToken(str: string) {
        await this.nvcWeb.setSpotAccessToken({ spotAccessToken: str })
    }
}
