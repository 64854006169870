import { Injectable } from '@angular/core'
import {
    LoggingLocalErrors,
    LoggingLocalRoutingEvents,
} from '../../classes-enums-interfaces-types/enums/enums.enum'
import { mGlobal } from '../../mglobal'
import { Storage } from '@ionic/storage-angular'

interface loggingLocalEntry {
    event: LoggingLocalErrors | LoggingLocalRoutingEvents
    timestamp: string
    error?: {
        message: string
        stack: string
    }
    message?: string
}

@Injectable({
    providedIn: 'root',
})
export class LoggingLocalService {
    private logKey = 'event_logs'
    private storage: Storage | null = null

    constructor(private storageModule: Storage) {
        this.init()
    }

    async init() {
        this.storage = await this.storageModule.create()
    }

    async addLog(
        loggingLocalEnum: LoggingLocalErrors | LoggingLocalRoutingEvents,
        { error = null, msg = null } : { error?: Error, msg?: any } = {}
    ) {
        if (mGlobal.logLocal) {
            const logEntry: loggingLocalEntry = {
                event: loggingLocalEnum,
                timestamp: new Date().toISOString(),
            }

            // Conditionally add optional properties if they exist
            if (error) {
                logEntry.error = {
                    message: error.message,
                    stack: error.stack,
                }
            }

            if (msg) {
                logEntry.message = msg
            }
            // Retrieve existing logs or initialize an empty array
            const logs = (await this.storage.get(this.logKey)) || []
            // Add the new log entry
            logs.push(logEntry)
            // Save the updated logs array
            await this.storage.set(this.logKey, logs)
        }
    }

    async getLogs() {
        return (await this.storage.get(this.logKey)) || []
    }

    async clearLogs() {
        await this.storage.remove(this.logKey)
    }
}
