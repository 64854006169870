import { ActivatedRoute } from '@angular/router';
import { AuthService } from './../../services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ENV } from '../../../../environments/environment';
import { LoggingLocalRoutingEvents, RouteEnum } from '../../classes-enums-interfaces-types/enums/enums.enum';
import { IMGlobalError } from '../../classes-enums-interfaces-types/interfaces/interfaces';
import { GlobalService } from '../../services/global.service';
import { HelperFunctionsService } from '../../services/helper-functions/helper-functions.service';
import { ToastController } from '@ionic/angular';
import { __await } from 'tslib';
import { mGlobal } from '../../mglobal';
import { LoggingLocalService } from '../../services/logging-local-service/logging-local-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit, AfterViewInit {

route : ActivatedRoute
  constructor(
      private http : HttpClient,

      private router : Router,
      private gS : GlobalService,
      private hF : HelperFunctionsService,
      public toastController : ToastController,
      private auth : AuthService,
      activatedRoute:ActivatedRoute,
	  private changeDetector: ChangeDetectorRef,
      private loggingLocal : LoggingLocalService

  ) { 
    this.route = activatedRoute
	
  }
	env = ENV
	preProd : string = ENV.PRE_PROD ? '_PP' : ''
	hasLoggedIn : boolean
	loginOrLogoutStr = 'Login'
	logoWithSubtitle = '../../../assets/pixelImages/logo-with-subtitle-dm-trans.png'
	spotLogo = '../../../assets/spotifyFullLogo.svg'

	ngOnInit() {



	}

	ngAfterViewInit(){
        this.auth.loggedIn$.subscribe(async (isLoggedIn)=>{
			this.hasLoggedIn = isLoggedIn
			this.changeDetector.detectChanges()
		})


	}

	ionViewDidEnter(){
        this.ionViewDidlEnterAsync()
		this.gS.setPendingHistoryBackClick(false)

        this.loggingLocal.addLog(LoggingLocalRoutingEvents.ReachedLoginPage)
   
	}

	async ionViewDidlEnterAsync(){
		this.hasLoggedIn = await this.auth.gethasLoggedIn()
		this.loginOrLogoutStr = this.hasLoggedIn ? 'Logout' : 'Login'
	}


	loginOrLogout(){
		if(this.auth.gethasLoggedIn()){
			this.auth.logout()
		} else {
			this.auth.login({redirectPath:'/find-tune', calledFromGui:true})
		}
	}



}
