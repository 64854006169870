import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* module.exports.ListElementTypesEnum =
{
    album : 'ALBUM',
    artist : "ARTIST",
    slimTuneTrack : 'SLIM_TUNE_TRACK', //
    tune : 'TUNE',
    track : "TRACK",
} 

module.exports.FadeCurveTypes = {
    linear : 'LINEAR', 
    exp : 'EXP',
    polynom2deg : 'POLYNOM2DEG'
}
 */

export enum MyLibrarySwtichSegment {

	tuneTriggers = 'TUNE_TRIGGERS',
	tuneLists = 'TUNE_LISTS',

}



export enum MyLibraryTuneListStates {
	multipleTuneLists = 'MULTIPLE_TUNE_LISTS',
	singleTuneList = 'SINGLE_TUNE_LIST',
}

export enum DevicePlatformE {
	android = 'ANDROID',
	ios = 'IOS',
	unknown = 'UNKNOWN'
}

// used to filter throwed global errors from mGlobalErrorService in route. To check if the route sub. should care
export enum RouteEnum {
    login = 'LOGIN_ROUTE'
}

//general
export enum ColorThemeE {
    light = 'light',
    dark = 'dark'
}

//for ng components, defined in styles.scss
export enum MNgThemesE {
	light = 'my-light-theme',
	dark = 'my-dark-theme'
}

/* exports.FadeCurveTypes = FadeCurveTypes 
exports.ListElementTypesEnum = ListElementTypesEnum */


//#Opt more detailed
export enum SaveTuneError {
    saveTuneServerError = 'Error saving Tune to server'
}

//#Opt more detailed
export enum UpdateTuneError {
    updateTuneError = 'Error saving Tune to server'
}

export enum SendTuneError {
    noTuneNumber = 'Tune has never been saved', // Should never occur
    shareNotSupported = 'Share was not supported',
    errorSharing = 'Failed to share' 
}

export enum SaveOrUpdateTuneError {
	setTuneSaveFields = 'Error setting Tune save fields',
	validateTuneError = 'Error validating Tune',
	saveTuneError = 'Error saving Tune',
	updateTuneError = 'Error updating Tune',
}

export enum TuneValidationError {
	noStartTime = 'Tune needs a start time',
	noStopTime = 'Tune needs a stop time',
    tooShort = 'Tune needs to have a message when it should be suggested',
    noTrigger = 'Tune needs to have a message when it should be suggested',
    noLyrics = 'Tune needs to have selected lyrics'
}

export enum SetSaveTuneFieldsError {
	settingLyrics = 'Failed to set lyrics',
	cloningTune = 'Failed to clone Tune'
}

/////////////////////// SW ////////////////////////
export enum VersionUpdateEventsE {
	versionUpdateDetected = 'VERSION_DETECTED',
	versionReady = 'VERSION_READY',
	versionFailedInstall = 'VERSION_INSTALLATION_FAILED',
	noVersionUpdateDetected = 'NO_NEW_VERSION_DETECTED'
}

export enum TuneListPageSwtich {
	noTuneLists = 'NO_TUNE_LISTS',
	tuneLists = 'TUNE_LISTS',
	tunes = 'TUNES'
}

export enum ModalsE{
	addToTuneList = 'ADD_TO_TUNE_LIST',
	createTuneList = 'CREATE_TUNE_LIST',
	renameTuneList = 'RENAME_TUNE_LIST',
	unsupportedInAppBrowser = 'UNSUPPORTED_IN_APP_BROWSER',
	notRecommendedBrowser = 'NOT_RECOMMENDED_BROWSER',
	fullScreenSpinner = 'FULL_SCREEN_SPINNER',
    readPrivateSpotListsInfo = 'READ_PRIVATE_SPOT_LISTS_INFO',
}
 
export enum ToastEnum {
	default = "TOAST_DEFAULT",
	success = "TOAST_SUCCESS",
	warning = "TOAST_WARNING",
	error = "TOAST_ERROR"
}
   
export enum UserSettingsKeysE{
	prefersColorTheme = 'prefersColorTheme',
	showLyricsOnFirstLoop = 'showLyricsOnFirstLoop',
	hideLyricsAlways = 'hideLyricsAlways',
    hideLibraryPermissionInfo = 'hideLibraryPermissionInfo'


}

export enum BrowsersE {
	chrome = 'chrome',

	safari = 'safari',

}

export enum ListElementHtmlContextE{
	emptyClass = '',
	myLibTriggerList = '--list-elem-name-max-width-my-lib-trigger-list',
	//myLibTuneListList = 'list-elem-my-lib-list-elem-tune-list'
	myLibTuneListList = '--list-elem-name-max-width-my-lib-tune-list-list',
	tuneListPage = '--list-elem-name-max-width-tune-list-page'
}

export enum LoggingLocalErrors {

    ErrorAuthServiceLogin = 'Error in AuthServic login()',

}

export enum LoggingLocalRoutingEvents {

    FeToBeHrefLogin = 'Auth serivice to href /login-backend',
    AuthServiceLoginToLoginPage = 'Auth serivice to login page',
    ReachedLoginPage = 'Reached login page',
    ReachedSignUpPage = 'Reached sign-up page',
    ReachedMyLibary = 'Reached my-library page',
    ReachedFindTunePage = 'Reached find-tune page',
    ReachedTuneList = 'Reached tune-list page',

}


